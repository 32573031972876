import React, { useState, useEffect } from 'react';
import history from '../../../services/history'
import $ from 'jquery'
import Select from 'react-select';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers, getAreasList } from '../../../state/redux/actions/index';
import { getVehiclesList } from '../../../state/redux/actions/index';
import { logoutOnExpiry, url } from './../../../services/requests'
import { colourStyles } from './../../../services/functions'

const AddDriver = (props) => {
    const dispatch = useDispatch()
    const data = props && props.dataState && props.dataState.data ? props.dataState.data : {}
    const status = props && props.dataState && props.dataState.update ? true : false
    const [updateObj, setUpdateObj] = useState({})
    const pageNumber = props && props.dataState && props.dataState.pagination && props.dataState.pagination.pageNumber ? props.dataState.pagination.pageNumber : ''
    const entries = props && props.dataState && props.dataState.pagination && props.dataState.pagination.entries ? props.dataState.pagination.entries : '';

    const vehicleListing = useSelector((state) => state.getVehicleRed.vehicleListing);
    const vehicleloader = useSelector((state) => state.getVehicleRed.loading);
    const message = useSelector((state) => state.getVehicleRed.message);
    const vehicleCount = useSelector((state) => state.getVehicleRed.count);

    const [userState, setUserState] = useState(
        {
            name: '',
            // password: "",
            // confirmPassword: "",
            email: "",
            mobile: "",
            // company: "",
            status: false,
            userType: "driver",
            maxTripDuration: "select",
            earliestTripTime: "select",
            latestTripTime: "select",
            waitingTime: "",
            driverRestTime: "",
            areaId: [],
            secondaryAreaId: [],
            othersAreaId: [],
            arealabel: "select",
            secArealabel: "select",
            othersArealabel: "select",
            eligibleForVehicle: "select",
            time: ""

        }
    )

    const [selectedVehicle, setSelectedVehicle] = useState([]);
    const [vehicleOption, setVehicleOption] = useState([]);
    const [vehicleFilter, setVehicleFilter] = useState({
        page: 1,
        fetchedCount: 0,
    });
    const [isVehicleUpdated, setIsVehicleUpdated] = useState(false);
    const [validation, setValidation] = useState({
        mobile: false,
        email: false
    })
    const [updateValidation, setUpdateValidation] = useState({
        mobile: true,
        email: true
    })
    const [areaListing, setAreaListing] = useState([])
    const [primaryArea, setPrimaryArea] = useState([])
    const [secondaryArea, setSecondaryArea] = useState([]);
    const [othersArea, setOthersArea] = useState([])
    const [passwordType, setPasswordType] = useState('password')
    const [passwordType1, setPasswordType1] = useState('password')
    const listing = useSelector((state) => state.getAreasRed.areaLists)

    const _handleChange = (e, item) => {
        let temp = { ...validation }
        if (item === 'mobile') {
            if (e.target.value.match(/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/)) {
                setUserState({ ...userState, [e.target.name]: e.target.value })
                temp.mobile = true
                setValidation(temp)
            }
            else {
                setUserState({ ...userState, [e.target.name]: e.target.value })
                temp.mobile = false
                setValidation(temp)
            }
        }
        else if (item === 'email') {
            if (e.target.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setUserState({ ...userState, [e.target.name]: e.target.value })
                temp.email = true
                setValidation(temp)
            }
            else {
                setUserState({ ...userState, [e.target.name]: e.target.value })
                temp.email = false
                setValidation(temp)
            }
        }
        else if (item === 'name') {
            var specialChars = /[^a-zA-Z ]/g;
            var len = e.target.value.length
            if (e.target.value.match(specialChars) || len >= 50) {
            }
            else {
                setUserState({ ...userState, [e.target.name]: e.target.value })
            }
        }
        else {
            setUserState({ ...userState, [e.target.name]: e.target.value })
        }

    }
    const _updateChange = (e, item) => {
        let temp = { ...updateValidation }
        if (item === 'mobile') {
            if (e.target.value.match(/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/)) {
                setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
                temp.mobile = true
                setUpdateValidation(temp)
            }
            else {
                setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
                temp.mobile = false
                setUpdateValidation(temp)
            }
        }
        else if (item === 'email') {
            if (e.target.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
                temp.email = true
                setUpdateValidation(temp)
            }
            else {
                setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
                temp.email = false
                setUpdateValidation(temp)
            }
        }
        else if (item === 'name') {
            var specialChars = /[^a-zA-Z ]/g;
            var len = e.target.value.length
            if (e.target.value.match(specialChars) || len >= 50) {
            }
            else {
                setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
            }
        }
        else {
            setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
        }
    }

    const timeSlots = [
        { label: '00', value: '00' },
        { label: '01', value: '01' },
        { label: '02', value: '02' },
        { label: '03', value: '03' },
        { label: '04', value: '04' },
        { label: '05', value: '05' },
        { label: '06', value: '06' },
        { label: '07', value: '07' },
        { label: '08', value: '08' },
        { label: '09', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
    ]
    const vehicleTypes = [
        // { label: 'select', value: 'select' },
        { label: 'CYCLE or TWO-WHEELER ', value: 'CYCLE or TWO-WHEELER ' },
        { label: 'LPMV', value: 'LPMV' },
        { label: 'HPMV', value: 'HPMV' },
    ]
    const handleStartChange = (item, val) => {
        let temp = { ...userState };
        // Ensure areaId is initialized as an array
        if (!Array.isArray(temp.areaId)) {
            temp.areaId = [];
        }

        // Similar checks for secondaryAreaId and othersAreaId
        if (!Array.isArray(temp.secondaryAreaId)) {
           temp.secondaryAreaId = [];
        }

        if (!Array.isArray(temp.othersAreaId)) {
            temp.othersAreaId = [];
        }
        if (val === 'maxTrip') {
            temp.maxTripDuration = item.value
            setUserState(temp)
        }
        else if (val === 'earliestStart') {
            temp.earliestTripTime = item.value
            setUserState(temp)
        }
        else if (val === 'vehicleType') {
            temp.eligibleForVehicle = item.value
            setUserState(temp)
        }
        else if (val === 'areaListing') {
            let dummy = [...primaryArea]
            let arr = [...areaListing]
            arr[item.id].isDisabled = true
            setAreaListing(arr)
            dummy.push(
                {
                    id: item.areaId,
                    label: item.label,
                    index: item.id
                }
            )
            setPrimaryArea([{
                id: item.areaId,
                label: item.label,
                index: item.id
            }])
            temp.areaId.push(item.areaId)
            temp.arealabel = item.label
            setUserState(temp)
        }
        else if (val === 'secondaryArea') {
            let dummy = [...secondaryArea]

            let arr = [...areaListing]
            arr[item.id].isDisabled = true
            setAreaListing(arr)

            dummy.push(
                {
                    id: item.areaId,
                    label: item.label,
                    index: item.id
                }
            )
            setSecondaryArea([{
                id: item.areaId,
                label: item.label,
                index: item.id
            }])

            temp.secondaryAreaId.push(item.areaId)
            temp.secArealabel = item.label
            setUserState(temp)
        }
        else if (val === 'othersArea') {
            let dummy = [...othersArea]

            let arr = [...areaListing]
            arr[item.id].isDisabled = true
            setAreaListing(arr)

            dummy.push(
                {
                    id: item.areaId,
                    label: item.label,
                    index: item.id
                }
            )
            setOthersArea(dummy)

            temp.othersAreaId.push(item.areaId)
            temp.othersArealabel = item.label
            setUserState(temp)
        }
        else {
            temp.latestTripTime = item.value
            setUserState(temp)
        }
    }
    const updateStartChange = (item, val) => {
        // console.log('update', item, val);
        let temp = { ...updateObj }
        // Ensure areaId is initialized as an array
        if (!Array.isArray(temp.areaId)) {
            temp.areaId = [];
        }

        // Similar checks for secondaryAreaId and othersAreaId
        if (!Array.isArray(temp.secondaryAreaId)) {
           temp.secondaryAreaId = [];
        }

        if (!Array.isArray(temp.othersAreaId)) {
            temp.othersAreaId = [];
        }
        if (val === 'maxTrip') {
            temp.maxTripDuration = item.value
            setUpdateObj(temp)
        }
        else if (val === 'earliestStart') {
            temp.earliestTripTime = item.value
            setUpdateObj(temp)
        }
        else if (val === 'vehicleType') {
            temp.eligibleForVehicle = item.value
            setUpdateObj(temp)
        }
        else if (val === 'areaListing') {
            let arr = [...areaListing]
            arr[item.id].isDisabled = true
            if (temp.areaId.length > 0) {
                temp.areaId.forEach((el, index) => {
                    const area = areaListing.find((e, i) => e.areaId === el);
                    if (area && area.id) {
                        arr[area.id].isDisabled = false
                    }
                })
            }
            setAreaListing(arr);

            temp.areaId = [item.areaId]
            temp.arealabel = item.label
            setUpdateObj(temp)
        }
        else if (val === 'secondaryArea') {
            let arr = [...areaListing]
            arr[item.id].isDisabled = true
            if (temp.secondaryAreaId.length > 0) {
                temp.secondaryAreaId.forEach((el, index) => {
                    const area = areaListing.find((e, i) => e.areaId === el);
                    if (area && area.id) {
                        arr[area.id].isDisabled = false
                    }
                })
            }
            setAreaListing(arr)
            temp.secondaryAreaId = [item.areaId]
            temp.arealabel = item.label
            setUpdateObj(temp)
        }
        else if (val === 'othersArea') {
            if (!temp.othersAreaId) {
                temp.othersAreaId = [];
            }
            temp.othersAreaId.push(item.areaId)
            let arr = [...areaListing]
            arr[item.id].isDisabled = true
            setAreaListing(arr)
            temp.otherslabel = item.label
            setUpdateObj(temp)
        }
        else if (val === 'vehicles') {
            temp.vehicles.push(item.areaId)
            temp.arealabel = item.label
            setUpdateObj(temp)
        }
        else {
            temp.latestTripTime = item.value
            setUpdateObj(temp)
        }
    }
    const submitUser = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let temp = { ...userState, vehicles: selectedVehicle };
        // console.log('final submit', temp)
        // if (temp.vehicles.length === 0) {
        //     return window.$.fn.show_notification({ message: 'Please select atleast one vehicle', title: 'Message!!', autohide: true, showInput: false }, (result) => {
        //     })
        // }
        if (temp.name === '') {
            window.$.fn.show_notification({ message: 'Name Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.mobile === '') {
            window.$.fn.show_notification({ message: 'Mobile Number Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.maxTripDuration === '' || temp.maxTripDuration === 'select') {
            window.$.fn.show_notification({ message: 'Max Trip Duration Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.earliestTripTime === '' || temp.earliestTripTime === 'select') {
            window.$.fn.show_notification({ message: 'Earliest Start Time Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.latestTripTime === '' || temp.latestTripTime === 'select') {
            window.$.fn.show_notification({ message: 'Latest Trip Time Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.waitingTime === '') {
            window.$.fn.show_notification({ message: 'Waiting Time Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.driverRestTime === '') {
            window.$.fn.show_notification({ message: 'Delivery Man Rest Time Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        // else if (temp.eligibleForVehicle === '' || temp.eligibleForVehicle === 'select') {
        //     window.$.fn.show_notification({ message: 'Vehicle Type Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
        //     })
        // }
        else if (temp.vehicles.length === 0) {
            return window.$.fn.show_notification({ message: 'Please select atleast one vehicle', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.areaId.length === 0) {
            window.$.fn.show_notification({ message: 'Primary Area Name Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.secondaryAreaId.length === 0) {
            window.$.fn.show_notification({ message: 'Secondary Area Name Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }

        else if (temp.mobile !== '' && validation.mobile === false) {
            window.$.fn.show_notification({ message: `Please enter valid Mobile Number.`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (temp.email !== '' && validation.email === false) {
            window.$.fn.show_notification({ message: `Please enter valid Email Address.`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            axios.post(`${url}/user/addUser`, { ...temp }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    dispatch(getUsers({
                        page: 1,
                        limit: 10,
                        searchType: "",
                        searchValue: "",
                        userType: "driver",
                        type: 'driver'
                    }))
                    dispatch(getVehiclesList({
                        page: 1,
                        limit: 10, // or whatever limit you are using
                    }));
                    if (status === true) {
                        setPrimaryArea([])
                        setSecondaryArea([])

                        setUserState({
                            name: '',
                            // password: "",
                            confirmPassword: "",
                            email: "",
                            mobile: "",
                            company: localStorage.getItem('name'),
                            status: false,
                            // latitude: "",
                            // longitude: "",
                            userType: "driver",
                            maxTripDuration: "",
                            earliestTripTime: "",
                            latestTripTime: "",
                            waitingTime: "",
                            driverRestTime: ""

                        })
                        empty()
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
    }
    const editDriver = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let temp = {
            _id: data && data.driverId ? data.driverId : '',
            updateDetails: { ...updateObj, vehicles: selectedVehicle, isVehicleUpdated : isVehicleUpdated }
        }
        console.log('final submit', new Date(), temp)
       
        if (temp.updateDetails.name === '') {
            window.$.fn.show_notification({ message: 'Name Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.mobile === '') {
            window.$.fn.show_notification({ message: 'Mobile Number Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.maxTripDuration === '') {
            window.$.fn.show_notification({ message: 'Max Trip Duration Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.earliestTripTime === '') {
            window.$.fn.show_notification({ message: 'Earliest Start Time Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.latestTripTime === '') {
            window.$.fn.show_notification({ message: 'Latest Trip Time Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        // else if (temp.updateDetails.eligibleForVehicle === '' || temp.updateDetails.eligibleForVehicle === 'select') {
        //     window.$.fn.show_notification({ message: 'Vehicle Type Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
        //     })
        // }
        else if (temp.updateDetails.vehicles.length === 0) {
            return window.$.fn.show_notification({ message: 'Please select atleast one vehicle', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.waitingTime === '') {
            window.$.fn.show_notification({ message: 'Waiting Time Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.driverRestTime === '') {
            window.$.fn.show_notification({ message: 'Delivery Man Rest Time Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.areaId === '') {
            window.$.fn.show_notification({ message: 'Area Name Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.areaId.length === 0) {
            window.$.fn.show_notification({ message: 'Primary Area Name Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.secondaryAreaId.length === 0) {
            window.$.fn.show_notification({ message: 'Secondary Area Name Required..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
            })
        }
        else if (temp.updateDetails.mobile !== '' && updateValidation.mobile === false) {
            window.$.fn.show_notification({ message: `Please enter valid Mobile Number.`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (temp.updateDetails.email !== '' && updateValidation.email === false) {
            window.$.fn.show_notification({ message: `Please enter valid Email Address.`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            axios.post(`${url}/user/editUser`, { ...temp }, { headers: config.headers })
                .then(response => {
                    let status = response.data.success
                    let message = response.data.message
                    if (status === true) {
                        dispatch(getUsers({
                            page: pageNumber,
                            limit: entries,
                            searchType: "",
                            searchValue: "",
                            userType: "driver",
                            type: 'driver'
                        }))
                        dispatch(getVehiclesList({
                            page: 1,
                            limit: 10, // or whatever limit you are using
                        }));
                        props.callBack();
                        empty()
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }

    }
    const _setPassType = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        }
        else {
            setPasswordType('password')
        }
    }
    const _setPassType1 = () => {
        if (passwordType1 === 'password') {
            setPasswordType1('text')
        }
        else {
            setPasswordType1('password')
        }
    }

    

    const empty = () => {
        setUserState(
            {
                name: '',
                // password: "",
                // confirmPassword: "",
                email: "",
                mobile: "",
                // company: "",
                status: false,
                userType: "driver",
                maxTripDuration: "select",
                earliestTripTime: "select",
                latestTripTime: "select",
                waitingTime: "",
                driverRestTime: "",
                areaId: "",
                arealabel: "select",
                secArealabel: 'select',
                othersArealabel: 'select',
                eligibleForVehicle: "select"

            }
        )
        setUpdateObj({
            id: '',
            name: '',
            // password: "",
            // confirmPassword: "",
            email: "",
            mobile: "",
            // company: "",
            status: false,
            userType: "driver",
            maxTripDuration: "select",
            earliestTripTime: "select",
            latestTripTime: "select",
            waitingTime: "",
            driverRestTime: "",
            areaId: "",
            arealabel: "select",
            secArealabel: "select",
            othersArealabel: "select",
            eligibleForVehicle: "select"
        })
        setPrimaryArea([])
        setSecondaryArea([])
        setOthersArea([]);
        setSelectedVehicle([])
        dispatch(getUsers({
            page: pageNumber || 1,
            limit: entries || 10,
            searchType: "",
            searchValue: "",
            userType: "driver",
            type: 'driver'
        }));
        dispatch(getVehiclesList({
            page: 1,
            limit: 10, // or whatever limit you are using
        }));
    }
    const removeArea = (item, ind) => {
        if (item === 'primary') {
            let temp = [...primaryArea]
            let dummy = { ...userState }
            let arr = [...areaListing]
            arr[temp[ind].index].isDisabled = false
            setAreaListing(arr)
            dummy.areaId.splice(ind, 1)
            setUserState(dummy)
            temp.splice(ind, 1)
            setPrimaryArea(temp)


        }
        else if (item === 'secondary') {
            let temp = [...secondaryArea]
            let dummy = { ...userState }

            let arr = [...areaListing]
            arr[temp[ind].index].isDisabled = false
            setAreaListing(arr)

            dummy.secondaryAreaId.splice(ind, 1)
            setUserState(dummy)
            temp.splice(ind, 1)
            setSecondaryArea(temp)
        }
        else {
            let temp = [...othersArea]
            let dummy = { ...userState }
            let arr = [...areaListing]
            arr[temp[ind].index].isDisabled = false
            setAreaListing(arr)
            dummy.othersAreaId.splice(ind, 1)
            setUserState(dummy)
            temp.splice(ind, 1)
            setOthersArea(temp)

        }
    }
    const removeAreaNew = (item, ind) => {
        // console.log('remove', item, ind)
        let temp = { ...updateObj }
        if (item === 'primary') {
            temp.areaId.splice(ind, 1)
            setUpdateObj(temp)

            // let arr = [...areaListing]
            // arr[temp[ind].index].isDisabled = false
            // setAreaListing(arr)
        }
        else if (item === 'secondary') {
            temp.secondaryAreaId.splice(ind, 1)
            setUpdateObj(temp)
        } else {
            // console.log('others==>', temp.othersAreaId)
            temp.othersAreaId.splice(ind, 1)
            setUpdateObj(temp)
        }
    }

    const fetchData = async (body) => {
        if (data && status === true) {
            try {
                const result = await handleApi('/vehicle/getVehiclesAllocatedToDriver', { ...body });
                if (result.success) {
                    const selectedVehicle = result.data.map((ele) => ({ label: ele.vehicleNo, value: ele._id, ...ele }));
                    // console.log('selectedVehicle', selectedVehicle)
                    setSelectedVehicle(selectedVehicle)
                }
                // console.log('api response ', result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };


    useEffect(() => {
        dispatch(getAreasList({
            page: 1,
            limit: 10,
        }))
        dispatch(getVehiclesList({
            page: vehicleFilter.page,
            limit: 10, // or whatever limit you are using
        }));
    }, [])

    useEffect(() => {
        let temp = { ...updateObj }
        if (data && status === true) {
            fetchData({ "_id": data._id });
            temp.id = data && data._id ? data._id : ''
            temp.name = data && data.name ? data.name : ''
            temp.email = data && data.email ? data.email : ''
            temp.mobile = data && data.mobile ? data.mobile : ''
            // temp.company = data && data.company ? data.company : ''
            temp.userType = 'driver'
            temp.maxTripDuration = data && data.settings ? data.settings.maxTripDuration : ''
            temp.earliestTripTime = data && data.settings ? data.settings.earliestTripTime : ''
            temp.latestTripTime = data && data.settings ? data.settings.latestTripTime : ''
            temp.waitingTime = data && data.settings ? data.settings.waitingTime : ''
            temp.driverRestTime = data && data.settings ? data.settings.driverRestTime : ''
            temp.areaId = data && data.settings ? data.settings.areaId : []
            temp.arealabel = data && data.settings ? data.settings.arealabel : ''
            temp.eligibleForVehicle = data && data.settings ? data.settings.eligibleForVehicle : ''
            temp.secondaryAreaId = data && data.settings ? data.settings.secondaryAreaId : []
            temp.othersAreaId = data && data.settings ? data.settings.othersAreaId : []
            temp.othersArealabel = data && data.settings ? data.settings.othersArealabel : ''
            temp.secArealabel = data && data.settings ? data.settings.secArealabel : ''
            temp.vehicles = data && data.settings ? data.settings.vehicles : []
            setUpdateObj(temp);
            let arr = [...areaListing]
            if (temp.areaId && temp.areaId.length > 0 && areaListing.length > 0) {
                temp.areaId.forEach((el, index) => {
                    const area = areaListing.find((e, i) => e.areaId === el);
                    // console.log('find area', el, area,)
                    if (area && area.id) {
                        arr[area.id].isDisabled = true
                    }
                })
            }
            if (temp.secondaryAreaId && temp.secondaryAreaId.length > 0 && areaListing.length > 0) {
                temp.secondaryAreaId.forEach((el, index) => {
                    const area = areaListing.find((e, i) => e.areaId === el);
                    // console.log('find sec area', el, area,)
                    if (area && area.id) {
                        arr[area.id].isDisabled = true
                    }
                })
            }
            if (temp.othersAreaId && temp.othersAreaId.length > 0 && areaListing.length > 0) {
                temp.othersAreaId.forEach((el, index) => {
                    const area = areaListing.find((e, i) => e.areaId === el);
                    // console.log('find other area', el, area,)
                    if (area && area.id) {
                        arr[area.id].isDisabled = true
                    }
                })
            }
            setAreaListing(arr)
        }
    }, [data])

    useEffect(()=> {
        if(listing && listing.length > 0){
            const updatedListing = listing.map((item, index) => {
                return ({ value: item.areaDescription, label: item.areaDescription, areaCode: item.areaCode, areaId: item.areaId, isDisabled: false, id: index });
            });
            setAreaListing(updatedListing);
        }

    },[listing])

    // When Redux state updates (new data fetched), update vehicleFilter
    useEffect(() => {
        if (vehicleListing?.length) {
            // console.log('vehicleListing==>', vehicleListing, vehicleOption, vehicleCount);
            let temp = [...vehicleOption];
            temp.splice((vehicleFilter.page - 1), 1, vehicleListing);
            setVehicleOption(temp);
            // console.log('vehicleListing-temp===>', temp);
        }
    }, [vehicleListing, vehicleCount]);

    // Handle scroll to load more
    const handleScrollToBottom = () => {
        // console.log('Scrolled to bottom', vehicleFilter);
        if (((vehicleFilter.page) * 10) < vehicleCount) {
            // console.log('Scrolled to bottom==>', (vehicleFilter.page)*10);
            setVehicleFilter(prevState => ({
                ...prevState,
                page: prevState.page + 1, // Increment page number for the next API call
            }));
            dispatch(getVehiclesList({
                page: vehicleFilter.page + 1,
                limit: 10, // or whatever limit you are using
            }));
        }
    };

    // console.log('areaListing==>', areaListing)

    return (
        <section id="AddDriver" className="custom-modal" style={{ width: '54%' }}>
            <div className="custom-modal-header">
                <h1 >{status === false ? "Create Delivery Man" : "Edit Delivery Man"}</h1>
                <span className="ct-close" onClick={() => { props.closeModal(); props.callBack(); empty() }}></span>
            </div>
            <div className="custom-modal-body" style={{ overflow: 'auto', maxHeight: '71vh' }}>
                <div className='latlngInpElem'>

                    <div className='user100'>
                        <div className='user33'>
                            <span className='userSpan'>Name <span className='asterick'> * </span> :</span>
                            <input name='name' value={status === false ? userState.name : updateObj.name}
                                onChange={status === false ? (e) => _handleChange(e, 'name') : (e) => _updateChange(e, 'name')}
                                className='userInp' type='text' placeholder='Enter Your Name' />
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Mobile Number <span className='asterick'> * </span> :</span>
                            <input name='mobile' value={status === false ? userState.mobile : updateObj.mobile}
                                onChange={status === false ? (e) => _handleChange(e, 'mobile') : (e) => _updateChange(e, 'mobile')}
                                className='userInp' type='number' placeholder='Enter Mobile Number' />
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Email :</span>
                            <input name='email' value={status === false ? userState.email : updateObj.email}
                                onChange={status === false ? (e) => _handleChange(e, 'email') : (e) => _updateChange(e, 'email')}
                                className='userInp' type='email' placeholder='Enter Email Address' />
                        </div>
                    </div>
                    <div className='user100'>
                        <div className='user33'>
                            <span className='userSpan'>Max Trip Duration <span className='asterick'> * </span>:</span>
                            {status === false ?
                                <Select
                                    onChange={(value) => handleStartChange(value, 'maxTrip')}
                                    options={timeSlots}
                                    styles={colourStyles}
                                    value={{ label: userState.maxTripDuration, value: userState.maxTripDuration }}
                                />
                                :
                                <Select
                                    onChange={(value) => updateStartChange(value, 'maxTrip')}
                                    options={timeSlots}
                                    styles={colourStyles}
                                    value={{ label: updateObj.maxTripDuration, value: updateObj.maxTripDuration }}
                                />
                            }
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Earliest Start Time <span className='asterick'> * </span>:</span>
                            {status === false ?
                                <Select
                                    onChange={(value) => handleStartChange(value, 'earliestStart')}
                                    options={timeSlots}
                                    styles={colourStyles}
                                    value={{ label: userState.earliestTripTime, value: userState.earliestTripTime }}
                                />
                                :
                                <Select
                                    onChange={(value) => updateStartChange(value, 'earliestStart')}
                                    options={timeSlots}
                                    styles={colourStyles}
                                    value={{ label: updateObj.earliestTripTime, value: updateObj.earliestTripTime }}
                                />
                            }
                        </div>

                        <div className='user33'>
                            <span className='userSpan'>Latest Trip End Time <span className='asterick'> * </span>:</span>
                            {status === false ?
                                <Select
                                    onChange={(value) => handleStartChange(value, 'latestTrip')}
                                    options={timeSlots}
                                    styles={colourStyles}
                                    value={{ label: userState.latestTripTime, value: userState.latestTripTime }}
                                />
                                :
                                <Select
                                    onChange={(value) => updateStartChange(value, 'latestTrip')}
                                    options={timeSlots}
                                    styles={colourStyles}
                                    value={{ label: updateObj.latestTripTime, value: updateObj.latestTripTime }}
                                />
                            }

                        </div>
                    </div>
                    <div className='user100'>
                        <div className='user33'>
                            <span className='userSpan'>Waiting Time (mins) <span className='asterick'> * </span>:</span>
                            <input name='waitingTime' value={status === false ? userState.waitingTime : updateObj.waitingTime}
                                onChange={status === false ? (e) => _handleChange(e) : (e) => _updateChange(e)}
                                className='userInp' type='number' placeholder='Enter Waiting Time' />
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Rest Time (mins) <span className='asterick'> * </span>:</span>
                            <input name='driverRestTime' value={status === false ? userState.driverRestTime : updateObj.driverRestTime}
                                onChange={status === false ? (e) => _handleChange(e) : (e) => _updateChange(e)}
                                className='userInp' type='number' placeholder='Enter Rest Time' />
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Vehicle <span className='asterick'> * </span>:</span>
                            <Select
                                options={vehicleOption.length 
                                    ? vehicleOption.flat()
                                        .filter(vehicle => updateObj?.id 
                                            ? (vehicle.allocatedTo_id === updateObj.id || !vehicle.hasOwnProperty('allocatedTo_id') || 
                                            vehicle.allocatedTo_id === null || 
                                            vehicle.allocatedTo_id === "") 
                                            : !vehicle.allocatedTo_id
                                        )
                                        .map(vehicle => ({
                                            label: vehicle.vehicleNo, 
                                            value: vehicle._id,
                                            ...vehicle
                                        }))
                                    : []}
                                isMulti
                                isLoading={vehicleloader}
                                value={selectedVehicle} // Add value prop to bind selected options
                                onMenuScrollToBottom={handleScrollToBottom} // Trigger scroll to load more
                                onChange={(selectedOptions) => {
                                    setIsVehicleUpdated(true)
                                    setSelectedVehicle(selectedOptions)
                                }
                                } // Handle selection setSelectedVehicle(selectedOptions
                            />
                        </div>
                        {/* <div className='user33'>
                            <span className='userSpan'>Vehicle Type <span className='asterick'> * </span>:</span>
                            {status === false ?
                                <Select
                                    onChange={(value) => handleStartChange(value, 'vehicleType')}
                                    options={vehicleTypes}
                                    styles={colourStyles}
                                    value={{ label: userState.eligibleForVehicle, value: userState.eligibleForVehicle }}
                                />
                                :
                                <Select
                                    onChange={(value) => updateStartChange(value, 'vehicleType')}
                                    options={vehicleTypes}
                                    styles={colourStyles}
                                    value={{ label: updateObj.eligibleForVehicle, value: updateObj.eligibleForVehicle }}
                                />
                            }
                        </div> */}

                    </div>
                    <div className='user100'>
                        <div className='user33'>
                            <span className='userSpan'>Primary Area Name <span className='asterick'> * </span>:</span>
                            {status === false ?
                                <Select
                                    onChange={(value) => handleStartChange(value, 'areaListing')}
                                    options={areaListing}
                                    styles={colourStyles}
                                    value={{ label: primaryArea.length !== 0 ? userState.arealabel : 'select', value: primaryArea.length !== 0 ? userState.areaId : 'select' }}
                                />
                                :
                                <Select
                                    onChange={(value) => updateStartChange(value, 'areaListing')}
                                    options={areaListing}
                                    styles={colourStyles}
                                    value={{ label: 'select', value: 'select' }}
                                />
                            }
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Secondary Area Name <span className='asterick'> * </span>:</span>
                            {status === false ?
                                <Select
                                    onChange={(value) => handleStartChange(value, 'secondaryArea')}
                                    options={areaListing}
                                    styles={colourStyles}
                                    value={{ label: secondaryArea.length !== 0 ? userState.secArealabel : 'select', value: secondaryArea.length !== 0 ? userState.secArealabel : 'select' }}
                                />
                                :
                                <Select
                                    onChange={(value) => updateStartChange(value, 'secondaryArea')}
                                    options={areaListing}
                                    styles={colourStyles}
                                    value={{ label: 'select', value: 'select' }}
                                />
                            }
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Other Area Name :</span>
                            {status === false ?
                                <Select
                                    onChange={(value) => handleStartChange(value, 'othersArea')}
                                    options={areaListing}
                                    styles={colourStyles}
                                    value={{ label: othersArea.length !== 0 ? userState.othersArealabel : 'select', value: othersArea.length !== 0 ? userState.othersArealabel : 'select' }}
                                />
                                :
                                <Select
                                    onChange={(value) => updateStartChange(value, 'othersArea')}
                                    options={areaListing}
                                    styles={colourStyles}
                                    value={{ label: 'select', value: 'select' }}
                                />
                            }
                        </div>
                        {/* {status === false ?
                            <div className='user33'>
                                <span className='userSpan'>Password <span className='asterick'> * </span>:</span>
                                <input name='password' value={userState.password} onChange={(e) => _handleChange(e)} className='userInp' type={passwordType} placeholder='Enter Password' />
                                {passwordType === 'password' ?
                                    <i className="fa fa-eye-slash eyeAbs" onClick={_setPassType} ></i>
                                    :
                                    <i className="fa fa-eye eyeAbs" onClick={_setPassType} ></i>
                                }
                            </div>
                            : ''} */}


                        {/* {status === false ?
                        <div className='user33'>
                            <span className='userSpan'>Confirm Password <span className='asterick'> * </span>:</span>
                            <input name='confirmPassword' value={userState.confirmPassword} onChange={(e) => _handleChange(e)} className='userInp' type={passwordType1} placeholder='Confirm Password' />
                            {passwordType1 === 'password' ?
                                <i className="fa fa-eye-slash eyeAbs" onClick={_setPassType1} ></i>
                                :
                                <i className="fa fa-eye eyeAbs" onClick={_setPassType1} ></i>
                            }
                        </div>
                        :''} */}
                    </div>

                    {status === false ?
                        <div className='user100 u100Auto'>
                            {primaryArea && primaryArea.length !== 0 ?
                                <div className='pKey'>
                                    <div className='pHead'>
                                        <span>Primary</span>
                                    </div>
                                    <div className='pCounts'>
                                        {primaryArea && primaryArea.map((item, ind) => {
                                            return (
                                                <div key={ind} className='pBtn'>{item.label}
                                                    <i className="fa fa-times pBtnCross" onClick={() => removeArea('primary', ind)}></i>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                : ''}

                            {secondaryArea && secondaryArea.length !== 0 ?
                                <div className='pKey' style={{ marginTop: '10px' }}>
                                    <div className='pHead'>
                                        <span>Secondary</span>
                                    </div>
                                    <div className='pCounts' >
                                        {secondaryArea && secondaryArea.map((item, ind) => {
                                            return (
                                                <div key={ind} className='pBtn'>{item.label}
                                                    <i className="fa fa-times pBtnCross" onClick={() => removeArea('secondary', ind)}></i>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                : ''}
                            {othersArea && othersArea.length !== 0 ?
                                <div className='pKey' style={{ marginTop: '10px' }}>
                                    <div className='pHead'>
                                        <span>Other</span>
                                    </div>
                                    <div className='pCounts' >
                                        {othersArea && othersArea.map((item, ind) => {
                                            return (
                                                <div key={ind} className='pBtn'>{item.label}
                                                    <i className="fa fa-times pBtnCross" onClick={() => removeArea('other', ind)}></i>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                : ''}
                        </div>
                        :
                        <div className='user100 u100Auto'>
                            {updateObj && updateObj.areaId && updateObj.areaId.length !== 0 ?
                                <div className='pKey'>
                                    <div className='pHead'>
                                        <span>Primary</span>
                                    </div>
                                    <div className='pCounts'>
                                        {updateObj && updateObj.areaId ? updateObj.areaId.map((item, ind) => {
                                            return (
                                                <div key={ind} className='pBtn'>{item}
                                                    <i className="fa fa-times pBtnCross" onClick={() => removeAreaNew('primary', ind)}></i>
                                                </div>
                                            )
                                        }) : ''}
                                    </div>
                                </div>
                                : ''}

                            {updateObj && updateObj.secondaryAreaId && updateObj.secondaryAreaId.length !== 0 ?
                                <div className='pKey' style={{ marginTop: '10px' }}>
                                    <div className='pHead'>
                                        <span>Secondary</span>
                                    </div>
                                    <div className='pCounts' >
                                        {updateObj && updateObj.secondaryAreaId ? updateObj.secondaryAreaId.map((item, ind) => {
                                            return (
                                                <div key={ind} className='pBtn'>{item}
                                                    <i className="fa fa-times pBtnCross" onClick={() => removeAreaNew('secondary', ind)}></i>
                                                </div>
                                            )
                                        }) : ''}
                                    </div>
                                </div>
                                : ''}
                            {updateObj && updateObj.othersAreaId && updateObj.othersAreaId.length !== 0 ?
                                <div className='pKey' style={{ marginTop: '10px' }}>
                                    <div className='pHead'>
                                        <span>Other</span>
                                    </div>
                                    <div className='pCounts' >
                                        {updateObj && updateObj.othersAreaId ? updateObj.othersAreaId.map((item, ind) => {
                                            return (
                                                <div key={ind} className='pBtn'>{item}
                                                    <i className="fa fa-times pBtnCross" onClick={() => removeAreaNew('other', ind)}></i>
                                                </div>
                                            )
                                        }) : ''}
                                    </div>
                                </div>
                                : ''}

                        </div>

                    }
                </div>
                <div className='createRouteBtn'>
                    {status === false ?
                        <button onClick={() => { submitUser(); props.returnFun(); }}>Create Delivery Man</button>
                        :
                        <button onClick={() => { editDriver(); props.returnFun(); }}>Edit Delivery Man</button>
                    }
                </div>
            </div>
        </section>
    )
}

export default AddDriver;



export const handleApi = async (requestUrl, body = {}) => {
    try {
        const token = localStorage.getItem('secretkey');
        const config = {
            headers: { Authorization: token }
        };
        const response = await axios.post(`${url}${requestUrl}`, { ...body }, config);

        const status = response.data.success;
        const message = response.data.message;

        if (status === true) {
            return response.data;  // Return response to the caller
        } else {
            window.$.fn.show_notification(
                { message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false },
                (result) => {
                    if (result.type === 'confirm') {
                        // Handle the confirm case if needed
                    }
                }
            );
        }
    } catch (error) {
        console.log('error', error);
        window.$.fn.show_notification(
            { message: 'Some Error Occured.', title: 'Message!!', type: 'confirm', autohide: true, showInput: false },
            (result) => {
                if (result.type === 'confirm') {
                    // Handle the confirm case if needed
                }
            }
        );

        // Check if the error status is 401
        if (error.response && error.response.status === 401) {
            logoutOnExpiry();  // Call your logout function when 401 occurs
        }
    }
}
