import React, { useEffect, useState } from 'react';
import history from '../../services/history'
import { getRequestList } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import AddRequest from './Modal/AddRequest'
import $ from 'jquery'
import moment from 'moment'
import axios from 'axios'
import { Table } from "react-bootstrap";
import { selectInit } from './../../services/functions'
import { logoutOnExpiry, url } from './../../services/requests'
import Select from 'react-select';
import { colourStyles } from './../../services/functions'


const Request = () => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getRequestRed.requestListing)
    const loader = useSelector((state) => state.getRequestRed.loading)
    const message = useSelector((state) => state.getRequestRed.message)
    const count = useSelector((state) => state.getRequestRed.count)
    const [pageCount, setPageCount] = useState(1)
    const [date, setDate] = useState('')
    const [pageLimit, setPageLimit] = useState(10)
    const [dataCopy, setDataCopy] = useState({
        data: '',
        update: '',
        pagination: { pageNumber: '', entries: '' }
    })
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        searchType: '',
        searchValue: ''
    })

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const shareData = (item) => {
        let concated = { ...dataCopy }
        concated.data = item
        concated.update = true
        concated.pagination.pageNumber = pageCount
        concated.pagination.entries = pageLimit
        setDataCopy(concated)
    }
    const deleteRequest = (id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: 'Delete this Request ?', title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {

                axios.post(`${url}/request/editRequest`, { _id: id }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        const message = response.data.message
                        if (status === true) {
                            setPageCount(1)
                            $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                            $(".custom-modal").removeClass("custom-modal-is-visible")
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            dispatch(getRequestList({
                                page: pageCount,
                                limit: pageLimit,
                                searchType: '',
                                searchValue: ''
                            }))
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        })

    }
    const callBack = () => {
        let concated = { ...dataCopy }
        concated.data = ''
        concated.update = false
        concated.pagination.entries = ''
        concated.pagination.pageNumber = ''
        setDataCopy(concated)
    }
    const nextPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount += 1);
        });
    }
    const prevPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount -= 1);
        });
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 50)
        dispatch(getRequestList({
            page: pageCount,
            limit: pageLimit,
            searchType: '',
            searchValue: ''
        }))
    }, [dispatch, pageCount])

    const _searchDrop = (id, value) => {
        let temp = { ...searchObj }
        temp.searchType = id
        setSearchObject(temp)

    }
    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(getRequestList({
            page: '',
            limit: pageLimit,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue
        }))
    }
    const _handleDateChange = (e) => {
        setDate(e.target.value)
    }
    const search = () => {
        dispatch(getRequestList({
            page: pageCount,
            limit: pageLimit,
            searchType: '',
            searchValue: '',
            requestDate: date, 
        }))
    }
    const returnFun = () => {
        // setPageCount(1)
    }

    const entries = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 }
    ]

    const handleChange = (item) => {
        let count = item.value
        setPageCount(1)
        setPageLimit(count)
        dispatch(getRequestList({
            page: 1,
            limit: item.value,
            searchType: '',
            searchValue: ''
        }))
    }

    const handleStatusSubmit = (request) => {
        // console.log(request);
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let payload = {
            _id: request._id,
            "updateDetails": {
                "orderStatusToUpdate": "offline Delivered",
            }
        }
        window.$.fn.show_notification({ message: 'Update this Request ?', title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {

                // console.log(payload);

                axios.post(`${url}/request/editRequest`, { ...payload }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        const message = response.data.message
                        if (status === true) {
                            setPageCount(1)
                            $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                            $(".custom-modal").removeClass("custom-modal-is-visible")
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            dispatch(getRequestList({
                                page: pageCount,
                                limit: pageLimit,
                                searchType: '',
                                searchValue: ''
                            }))
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        })
    }
    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        Request
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div className='bdaOuter'>
                            <div className='searcBox alignCen' style={{  justifyContent: 'space-between' }} >
                                <div className='cust-col-4-new'>
                                    <div className="input-group">
                                        <div className="input-group-prepend" style={{ width: '30%', zIndex: '10', marginRight: '15px' }}>
                                            <select className="select2-single" defaultValue="select" id="vehicleSearch">
                                                <option value="select" disabled>Select</option>
                                                <option value="requestId">Request Id</option>
                                                <option value="contactDetail.personName">Customer Name</option>
                                            </select>
                                        </div>
                                        <input type="text" name='searchValue' value={searchObj.searchValue} onChange={(e) => _handleChange(e)} className="form-control" placeholder="Search here..." style={{ zIndex: '10' }} />
                                        <div className="input-group-append" onClick={() => triggerSearch()}>
                                            <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                             
                            </div>
                            <div className='header30' style={{width: '350px', margin:'8px' }}>
                                    <div className='headerSpan' style={{ justifyContent: 'space-Between', width: '22%' }}>
                                        Date :
                                    </div>
                                    <div className='headerInput' style={{ width: '100%' }}>
                                        {/* <input min={new Date().toISOString().split('T')[0]} className='dateInput' type='date' onChange={(e) => _handleDateChange(e)} /> */}
                                        <input className='dateInput' type='date' onChange={(e) => _handleDateChange(e)} /> 
                                        <div className='searchDiv' onClick={() => search()} >
                                            <i className="fa fa-search searchIcon"  ></i>
                                        </div>
                                    </div>
                                </div>

                            <div className='mapView'>
                                <button className='myMapBtnGrey mgRight' onClick={() => { _handleClick('AddRequest') }} >Add Request</button>
                            </div>
                        </div>

                        {loader === false ?
                            listing && listing.length !== 0 ?
                                <div className="generic-table-wrapper mt20">
                                    <Table className="generic-table">
                                        <thead className="bg-accent">
                                            <tr>
                                                <th>S. No</th>
                                                <th>Request ID</th>
                                                <th>Contact Details</th>
                                                <th>Address</th>
                                                <th>Quantity</th>
                                                <th>Date</th>
                                                <th>Time Slot</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            {listing && listing.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ color: '#007BC9' }}>
                                                            {((pageCount - 1) * (pageLimit)) + index + 1}
                                                        </td>
                                                        <td>{item.requestId ? item.requestId : '-'}</td>
                                                        <td>{item.contactDetail && item.contactDetail.personName ? item.contactDetail.personName : ''} , {item.contactDetail && item.contactDetail.mobileNo ? item.contactDetail.mobileNo : ''} , {item.contactDetail && item.contactDetail.email ? item.contactDetail.email : ''}</td>
                                                        <td>{item.address ? item.address.address : '-'}</td>

                                                        <td>{item.quantity ? item.quantity : '-'}</td>
                                                        <td>{item.date ? moment(item.date).format("DD-MM-YYYY") : '-'}</td>
                                                        <td>{item.startSlotTime}:00 - {item.endSlotTime}:00</td>
                                                        <td>
                                                            <p style={{color: item?.orderStatus === "ready" || item?.orderStatus === "order Delivered Offline" ? "green" : 'blue'}}>{item?.orderStatus === "ready" ? 'Ready' : item?.orderStatus === "order Delivered Offline" ? "Delivered Offline" : "Trip Initiated"}</p>

                                                            {/* <>
                                                                <i className="fa fa-pencil-square-o editIcon"
                                                                    onClick={() => { _handleClick('AddRequest'); shareData(item) }}
                                                                    style={{ marginRight: '10px' }} ></i>
                                                                <i className="fa fa-trash trashIcon" onClick={() => deleteRequest(item._id)}></i>
                                                            </> */}
                                                        </td>
                                                        <td>
                                                            {item?.orderStatus === "ready" ? <button style={{height : 'auto'}} className='myMapBtnGrey' onClick={() => handleStatusSubmit(item)}>
                                                                Update Status
                                                            </button> : '-'}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                :
                                <div className='noData'>
                                    <p>{message}</p>
                                </div>
                            :
                            <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                            </div>

                        }
                        {listing && listing.length !== 0 ?
                            <div className='paginationDiv'>
                                <div className='entries'>
                                    <span className='entrieSpan'>Show Entries :</span>
                                    <Select
                                        onChange={(value) => handleChange(value)}
                                        options={entries}
                                        styles={colourStyles}
                                    />
                                </div>
                                <button disabled={pageCount === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
                                <button disabled={Math.floor((count + pageLimit - 1) / pageLimit) === pageCount ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
                            </div>
                            : ''}
                        {/* </div> */}
                    </div>
                </div>
            </div>
            <AddRequest closeModal={_closeModal} info={dataCopy} callBack={callBack} returnFun={returnFun} />
        </div>
    )
}
export default Request;