import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from "react-bootstrap";
import Select from 'react-select';
import { colourStyles } from './../../services/functions'
import { getAreasList } from '../../state/redux/actions/index';

const AreaManagement = () => {
    const dispatch = useDispatch();
    const { areaLists: listing, loading: loader, message } = useSelector((state) => state.getAreasRed);
    
    // Define state variables for pagination
    const [pageCount, setPageCount] = useState(1);
    const [count, setCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(10); // Set your desired page limit

    useEffect(() => {
        dispatch(getAreasList({ page: pageCount, limit: pageLimit }));
    }, [dispatch, pageCount, pageLimit]);

    // Placeholder for entries and styles
    const entries = [
        {label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 }
    ];

    const handleChange = (selectedOption) => {
        // Handle the change event for showing entries
        setPageLimit(selectedOption.value);
    };

    const prevPage = () => {
        if (pageCount > 1) {
            setPageCount(prev => prev - 1);
        }
    };

    const nextPage = () => {
        setPageCount(prev => prev + 1);
    };

    const renderTableRows = () => {
        return listing.map((item, index) => (
            <tr key={index}>
                <td style={{ color: '#007BC9' }}>{index + 1}</td>
                <td>{item.areaDescription || '-'}</td>
                <td>{item.areaId || '-'}</td>
                <td>{item.areaCode || '-'}</td>
            </tr>
        ));
    };

    const renderLoader = () => (
        <div className='loaderDiv'>
            <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl"></i>
        </div>
    );

    const renderNoData = () => (
        <div className='noData'>
            <p>{message}</p>
        </div>
    );

    const renderTable = () => (
        <Table className="generic-table">
            <thead className="bg-accent">
                <tr>
                    <th>S. No</th>
                    <th>Area Description</th>
                    <th>Area ID</th>
                    <th>Area Code</th>
                </tr>
            </thead>
            <tbody className="bg-gray-100">
                {renderTableRows()}
            </tbody>
        </Table>
    );

    const hasData = listing && listing.length > 0;

    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">Area Management</h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color"></h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div className="cust-row">
                    <div className="cust-col-10">
                        {loader ? renderLoader() : hasData ? (
                            <div className="generic-table-wrapper mt20">
                                {renderTable()}
                            </div>
                        ) : renderNoData()}

                        {/* {hasData && (
                            <div className='paginationDiv'>
                                <div className='entries'>
                                    <span className='entrieSpan'>Show Entries :</span>
                                    <Select
                                        onChange={handleChange}
                                        options={entries}
                                        styles={colourStyles}
                                        menuPlacement="auto"
                                    />
                                </div>
                                <button disabled={pageCount === 1} className='paginationBtn' onClick={prevPage}>Previous</button>
                                <button disabled={Math.floor((count + pageLimit - 1) / pageLimit) === pageCount} className='paginationBtn' onClick={nextPage}>Next</button>
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AreaManagement;


// import { getAreasList } from '../../state/redux/actions/index';
// import { useDispatch, useSelector } from 'react-redux'
// import { selectInit } from './../../services/functions'
// import {Table } from "react-bootstrap"
// const AreaManagement = () => {
//     const dispatch = useDispatch()
//     const listing = useSelector((state) => state.getAreasRed.areaLists)
//     const loader = useSelector((state) => state.getAreasRed.loading)
//     const message = useSelector((state) => state.getAreasRed.message)
//     const _searchDrop = (id, value) => {
//         // let temp = { ...searchObj }
//         // temp.searchType = id
//         // setSearchObject(temp)

//     }
//     useEffect(() => {
//         setTimeout(() => {
//             selectInit(_searchDrop)
//         }, 50)
//         dispatch(getAreasList({
//             page: 1,
//             limit: 10,
//         }))
//     }, [])

//     return (
//         <div className="contentpanel">
//             <div className="cust-row flex-algn-cent">
//                 <div className='cust-col-5'>
//                     <h1 className="title">
//                         Area Management
//                     </h1>
//                 </div>
//                 <div className='cust-col-5 flex-jc-end'>
//                     <h1 className="title font-bold orange_color">
//                     </h1>
//                 </div>
//             </div>
//             <hr className='bgg' />
//             <div className="cust-container">
//                 <div class="cust-row">
//                     <div class="cust-col-10">
//                         {/* <div className='bdaOuter'>
//                             <div className='searcBox alignCen' >
//                                 <div className='cust-col-4-new'>
//                                     <div className="input-group">
//                                         <div className="input-group-prepend" style={{ width: '30%', zIndex: '10', marginRight: '15px' }}>
//                                             <select className="select2-single" defaultValue="select" id="vehicleSearch">
//                                                 <option value="select" disabled>Select</option>
//                                                 <option value="vehicleNo">Vehicle Number</option>
//                                             </select>
//                                         </div>
//                                         <input type="text" name='searchValue' className="form-control" placeholder="Search here..." style={{ zIndex: '10' }} />
//                                         <div className="input-group-append" >
//                                             <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
//                                                 <i className="fa fa-search"></i>
//                                             </button>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className='cust-col-2'>
//                                 </div>
//                             </div>
//                             <div className='mapView'>
//                                 <button className='myMapBtnGrey' onClick={() => { _handleClick('AddVehicle') }}> Add Vehicle</button>
//                             </div>
//                         </div> */}

                      

//                         {loader === false ?
//                             listing && listing.length !== 0 ?
//                                 <div className="generic-table-wrapper mt20">
//                                     <Table className="generic-table">
//                                         <thead className="bg-accent">
//                                             <tr>
//                                                 <th>S. No</th>
//                                                 <th>Area Description</th>
//                                                 <th>Area ID</th>
//                                                 <th>Area Code</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody className="bg-gray-100">
//                                             {listing && listing.map((item, index) => {
//                                                 return (
//                                                     <tr key={index}>
//                                                         <td style={{ color: '#007BC9' }}>
//                                                            {index+1}
//                                                         </td>
//                                                         <td>{item.areaDescription ? item.areaDescription : '-'}</td>
//                                                         <td>{item.areaId ? item.areaId : '-'}</td>
//                                                         <td >{item.areaCode ? item.areaCode : '-'}</td>
//                                                     </tr>
//                                                 )
//                                             })}
//                                         </tbody>
//                                     </Table>
//                                 </div>
//                                 :
//                                 <div className='noData'>
//                                     <p>{message}</p>
//                                 </div>
//                             :
//                             <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
//                                 <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
//                             </div>

//                         }
//                     </div>
//                 </div>
//             </div>

//         </div>
//     )
// }
// export default AreaManagement;