import React, { useState, useEffect, useRef } from 'react';
import history from '../../../services/history'
import $ from 'jquery'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import axios from 'axios'
import { getAddress } from './../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { logoutOnExpiry , url } from './../../../services/requests'
import { colourStyles } from './../../../services/functions'

const AddAddress = (props) => {
    const markerRef = useRef();
    const dispatch = useDispatch()
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [address, setAddress] = useState('')
    const data = props && props.info && props.info.data ? props.info.data : ''
    const status = props && props.info && props.info.update ? props.info.update : ''
    const pageNumber = props && props.info && props.info.pagination && props.info.pagination.pageNumber ? props.info.pagination.pageNumber : ''
    const entries = props && props.info && props.info.pagination && props.info.pagination.entries ? props.info.pagination.entries : ''

    console.log('data', props.info)
    const [dataCopy, setCopyData] = useState({})
    const [map, setMap] = useState(false)
    const [validation, setValidation] = useState({
        mobile: false,
        email : false
    })
    const [updateValidation, setUpdateValidation] = useState({
        mobile: true,
        email : true
    })
    const [newCords, setNewCords] = useState({
        latNew: '',
        lngNew: ''
    })
    // const [slotTime, setSlotTime] = useState({
    //     startSlotTime: '',
    //     endSlotTime: ''
    // })

    useEffect(() => {
        let dummy = { ...dataCopy }
        dummy.company = data.company
        dummy.address = data.address
        dummy.latitude = data.latitude
        dummy.longitude = data.longitude
        dummy.personName = data && data.contactDetails && data.contactDetails.personName ? data.contactDetails.personName : ''
        dummy.mobileNo = data && data.contactDetails && data.contactDetails.mobileNo ? data.contactDetails.mobileNo : ''
        dummy.email = data && data.contactDetails && data.contactDetails.email ? data.contactDetails.email : ''
        setCopyData(dummy)
    }, [data])

    const [addAddresState, setAddAddress] = useState({
        company: '',
        address: '',
        latitude: '',
        longitude: '',
        personName: '',
        mobileNo: '',
        email: ''
    })
    const _handleChange = (e, item) => {
        let temp = {...validation}
        if (item === 'lat') {
            setAddAddress({ ...addAddresState, [e.target.name]: parseFloat(e.target.value) })
        }
        else if (item === 'lng') {
            setAddAddress({ ...addAddresState, [e.target.name]: parseFloat(e.target.value) })
        }
        else if (item === 'name') {
            var specialChars = /[^a-zA-Z ]/g;
            var len = e.target.value.length
            if(e.target.value.match(specialChars) || len >= 50){
            }
            else{
                setAddAddress({ ...addAddresState, [e.target.name]: e.target.value })
            }
        }
        else if (item === 'phone') {
            if(e.target.value.match(/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/)){
                setAddAddress({ ...addAddresState, [e.target.name]: e.target.value })
                temp.mobile = true
                setValidation(temp) 
            }
            else{
                setAddAddress({ ...addAddresState, [e.target.name]: e.target.value })
                temp.mobile = false
                setValidation(temp) 
            }
        }
        else if (item === 'email') {
            if(e.target.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                    setAddAddress({ ...addAddresState, [e.target.name]: e.target.value })
                    temp.email = true
                    setValidation(temp) 
              }
              else{
                setAddAddress({ ...addAddresState, [e.target.name]: e.target.value })
                temp.email = false
                setValidation(temp) 
              } 
        }
        else {
            setAddAddress({ ...addAddresState, [e.target.name]: e.target.value })
        }
    }
    const _update = (e, item) => {
        let temp ={...updateValidation}
        if (item === 'lat') {
            setCopyData({ ...dataCopy, [e.target.name]: parseFloat(e.target.value) })
        }
        else if (item === 'lng') {
            setCopyData({ ...dataCopy, [e.target.name]: parseFloat(e.target.value) })
        }
        else if (item === 'name') {
            var specialChars = /[^a-zA-Z ]/g;
            var len = e.target.value.length
            if(e.target.value.match(specialChars) || len >= 50){
            }
            else{
                setCopyData({ ...dataCopy, [e.target.name]: e.target.value })
            }
        }
        else if (item === 'phone') {
           
            if(e.target.value.match(/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/)){
                setCopyData({ ...dataCopy, [e.target.name]: e.target.value })
                temp.mobile = true
                setUpdateValidation(temp) 
            }
            else{
                setCopyData({ ...dataCopy, [e.target.name]: e.target.value })
                temp.mobile = false
                setUpdateValidation(temp) 
            }
        }
        else if (item === 'email') {
            if(e.target.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                setCopyData({ ...dataCopy, [e.target.name]: e.target.value })
                temp.email = true
                setUpdateValidation(temp) 
          }
          else{
            setCopyData({ ...dataCopy, [e.target.name]: e.target.value })
            temp.email = false
            setUpdateValidation(temp) 
          }
            
        }
        else {
            setCopyData({ ...dataCopy, [e.target.name]: e.target.value })
        } 
    }
    const AddAddressBtn = () => {
        let finalObj = { ...addAddresState }
        let contactDetails = {
            personName: addAddresState.personName,
            mobileNo: addAddresState.mobileNo,
            email: addAddresState.email
        }
        let concated = { ...finalObj, contactDetails }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if(concated.company === ''){
            window.$.fn.show_notification({ message: `Enter Company Name`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(concated.personName === ''){
            window.$.fn.show_notification({ message: `Enter Person Name`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(concated.address === ''){
            window.$.fn.show_notification({ message: `Enter Address`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(concated.latitude === ''){
            window.$.fn.show_notification({ message: `Enter Latitude`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(concated.longitude === ''){
            window.$.fn.show_notification({ message: `Enter Longitude`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(concated.mobileNo === ''){
            window.$.fn.show_notification({ message: `Enter Mobile No`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(concated.email === ''){
            window.$.fn.show_notification({ message: `Enter Email Id`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (concated.mobileNo !== '' && validation.mobile === false){
            window.$.fn.show_notification({ message: `Please enter valid Mobile Number.`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (concated.email !== '' && validation.email === false){
            window.$.fn.show_notification({ message: `Please enter valid Email Address.`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else{
            axios.post(`${url}/route/addAddress`, { ...concated }, { headers: config.headers })
                .then(response => {
                    const addressRes = response.data
                    const status = response.data.success
                    const message = response.data.message
                  
                    if (status === true) {
                        dispatch(getAddress({
                            page: 1,
                            limit: 10,
                            searchType: '',
                            searchValue: ''
                        }))
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        setAddAddress({
                            company: '',
                            address: '',
                            latitude: '',
                            longitude: '',
                            personName: '',
                            mobileNo: '',
                            email: ''
                        })
                        addAddresState.email = ''
                        setAddress('')
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
       
      
    }
    const EditAddress = () => {
       
        let finalObj = {
            _id: data._id,
            updateDetails: {
                latitude: dataCopy.latitude,
                longitude: dataCopy.longitude,
                address: dataCopy.address,
                company: dataCopy.company,
                contactDetails: {
                    personName: dataCopy.personName,
                    mobileNo: dataCopy.mobileNo,
                    email: dataCopy.email
                }
            }
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if(finalObj.updateDetails.company === ''){
            window.$.fn.show_notification({ message: `Enter Company Name`, title: 'Message!!',   autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(finalObj.updateDetails.contactDetails.personName === ''){
            window.$.fn.show_notification({ message: `Enter Person Name`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(finalObj.updateDetails.address === ''){
            window.$.fn.show_notification({ message: `Enter Address`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(finalObj.updateDetails.latitude === ''){
            window.$.fn.show_notification({ message: `Enter Latitude`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(finalObj.updateDetails.longitude === ''){
            window.$.fn.show_notification({ message: `Enter Longitude`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(finalObj.updateDetails.contactDetails.mobileNo === ''){
            window.$.fn.show_notification({ message: `Enter Mobile No`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(finalObj.updateDetails.contactDetails.email === ''){
            window.$.fn.show_notification({ message: `Enter Email Id`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (finalObj.updateDetails.contactDetails.mobileNo !== '' && updateValidation.mobile === false){
            window.$.fn.show_notification({ message: `Please enter valid Mobile Number.`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (finalObj.updateDetails.contactDetails.email !== '' && updateValidation.email === false){
            window.$.fn.show_notification({ message: `Please enter valid Email Address.`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else{
            axios.post(`${url}/route/editAddress`, { ...finalObj }, { headers: config.headers })
            .then(response => {
                const status = response.data.success
                const message = response.data.message
                if (status === true) {
                    dispatch(getAddress({
                        page: pageNumber,
                        limit: entries,
                        searchType: '',
                        searchValue: ''
                    }))
                    props.callBack();
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.$.fn.show_notification({ message: `Address updated successfully!!`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                            // window.location.reload()
                        }
                    })
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
        }
       
    }
    const _closeModal = () => {
        setAddAddress({
            company: '',
            address: '',
            latitude: '',
            longitude: '',
            personName: '',
            mobileNo: ''
        })
        addAddresState.email = ''
        setAddress('')
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const showAddInp = () => {
        if ($(".addressRow").hasClass("close")) {
            $(".addressRow").removeClass("close");
            $(".addressRow").addClass("open");
            $(".ct100").removeClass("open");
            $(".ct100").addClass("close");

        } else {
            $(".addressRow").removeClass("open");
            $(".addressRow").addClass("close")
            $(".ct100").removeClass("close");
            $(".ct100").addClass("open");
        }
    }
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        })
        geocodeByAddress(addAddresState.address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setLat(latLng.lat)
                setLng(latLng.lng)
            })
            .catch(error => console.error('Error', error));
    }, [addAddresState.address])

    const handleChange = (item) => {
        let local = { ...addAddresState }
        local.address = item
        setAddAddress(local)
        dataCopy.address = item
        setAddress(item)
    };
    const handleSelect = (item) => {
        let local = { ...addAddresState }
        local.address = item
        dataCopy.address = item
        geocodeByAddress(item)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setLat(latLng.lat)
                setLng(latLng.lng)
                if (status !== true) {
                    local.latitude = latLng.lat
                    local.longitude = latLng.lng
                    setAddAddress(local)
                }
                else {
                    let dummy = { ...dataCopy }
                    dummy.latitude = latLng.lat
                    dummy.longitude = latLng.lng
                    setCopyData(dummy)
                }
            })
            .catch(error => console.error('Error', error));
    };
    const locateOnMap = () => {
        setMap(true)
        if (status === true) {
            setLat(dataCopy.latitude)
            setLng(dataCopy.longitude)
        }
    }
    const _closeMapModal = () => {
        setMap(false)
    }
    const changeAddress = () => {
        setMap(false)
        if (status !== true) {
            let temp = { ...addAddresState }
            temp.latitude = newCords.latNew
            temp.longitude = newCords.lngNew
            setAddAddress(temp)
            setLat(newCords.latNew)
            setLng(newCords.lngNew)
        }
        else{
            let local = {...dataCopy}
            local.latitude = newCords.latNew
            local.longitude = newCords.lngNew
            setCopyData(local)
            setLat(newCords.latNew)
            setLng(newCords.lngNew)
        }

    }
    const DragEnd = (e) => {
        let newLat = e.latLng.lat()
        let newLng = e.latLng.lng()
        newCords.latNew = newLat
        newCords.lngNew = newLng
    }
  
    return (
        <>
            {map === false ?
                <section id="AddAddressModal" className="custom-modal" style={{ width: '60%' }}>
                    <div className="custom-modal-header">
                        <h1>{status !== true ? 'Add Address' : 'Edit Address'}</h1>
                        <span className="ct-close" onClick={() => { _closeModal(); props.callBack(); }}></span>
                    </div>
                    <div className="custom-modal-body" style={{overflowX:'auto', maxHeight: '70vh' }}>
                        <div className='latlngInpInn'>
                            <div className='ct1001'>
                                <div className='latElem'>
                                    <div className='newwidthStyle'>
                                        <span className='latElemSpan'>Company <span className='asterick'> * </span> :</span>
                                    </div>
                                    <input className='latlngField' name='company'
                                        value={status !== true ? addAddresState.company : dataCopy.company}
                                        onChange={(e) => status !== true ? _handleChange(e) : _update(e)}
                                        type='text' placeholder='Enter Company Name'
                                    />
                                </div>
                                <div className='latElem'>
                                    <div className='newwidthStyle'>
                                        <span className='latElemSpan'>Contact Name <span className='asterick'> * </span> : </span>
                                    </div>
                                    <input className='latlngField'
                                        name='personName'
                                        value={status !== true ? addAddresState.personName : dataCopy && dataCopy.personName ? dataCopy.personName : ''}
                                        onChange={(e) => status !== true ? _handleChange(e, 'name') : _update(e, 'name')}
                                        type='text' placeholder='Enter Contact Person Name'
                                    />
                                </div>
                            </div>
                            <button className='addreddBtn1' style={{ display:'none' }} onClick={() => showAddInp()}>Enter Address</button>
                        </div>

                        <div className='latlngInpInn' style={{flexWrap:'unset', justifyContent:'normal'}}>
                            <div className='ct1001'>
                                <div className='latElem'>
                                    <div className='newwidthStyle'>
                                        <span className='latElemSpan' >Address <span className='asterick'> * </span> :</span>
                                    </div>
                                    <div className='latlngFieldNew'>
                                        <PlacesAutocomplete
                                            name='address'
                                            value={status !== true ? addAddresState.address === '' ? address : addAddresState.address : dataCopy.address}
                                            onChange={(e) => handleChange(e)}
                                            onSelect={(e) => handleSelect(e)}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className='placeHolderWidth'>
                                                    <input
                                                        id='widthh'
                                                        value={addAddresState.address}
                                                        {...getInputProps({
                                                            placeholder: 'Search Places ...',
                                                            className: 'location-search-input',


                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#007BC9', cursor: 'pointer', color: '#fff', fontSize: '0.8rem', width: '100%', borderRadius: '3px', padding: '3px' }
                                                                : {
                                                                    backgroundColor: '#ffffff', cursor: 'pointer', fontSize: '0.8rem', border: '1px solid rgb(0 0 0 / 21%)', width: '100%',
                                                                    padding: '3px', borderRadius: '3px'
                                                                };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                </div>

                            </div>
                            {/* <button className='addreddBtn1' style={{ visibility: 'hidden' }} onClick={() => showAddInp()}>Enter Address</button> */}

                            <button className='addreddBtn1' disabled={status !== true ? addAddresState.address === '' ? true : false : false} onClick={() => locateOnMap()}>Locate On Map</button>
                        </div>

                        <div className='latlngInpInn'>
                            <div className='ct1001'>
                                <div className='latElem'>
                                    <div className='newwidthStyle'>
                                        <span className='latElemSpan'>Latitude <span className='asterick'> * </span> :</span>
                                    </div>
                                    <input type='number' className='latlngField' placeholder='Enter Latitude' name='latitude'
                                        value={status !== true ? addAddresState.latitude : dataCopy.latitude}
                                        onChange={(e) => status !== true ? _handleChange(e, 'lat') : _update(e, 'lat')}
                                    />
                                </div>
                                <div className='latElem'>
                                    <div className='newwidthStyle'>
                                        <span className='latElemSpan'>Longitude <span className='asterick'> * </span> :</span>
                                    </div>

                                    <input type='number' className='latlngField' placeholder='Enter Longitude'
                                        name='longitude'
                                        value={status !== true ? addAddresState.longitude : dataCopy.longitude}
                                        onChange={(e) => status !== true ? _handleChange(e, 'lng') : _update(e, 'lng')}
                                    />
                                </div>
                            </div>

                            <button className='addreddBtn1' style={{ display:'none' }} onClick={() => showAddInp()}>Enter Address</button>
                        </div>

                        <div className='latlngInpInn'>
                            <div className='ct1001'>
                                <div className='latElem'>
                                    <div className='newwidthStyle'>
                                        <span className='latElemSpan'>Mobile No. <span className='asterick'> * </span> :</span>
                                    </div>
                                    <input type='number' className='latlngField' placeholder='Enter Mobile no'
                                        name='mobileNo'
                                        value={status !== true ? addAddresState.mobileNo : dataCopy && dataCopy.mobileNo ? dataCopy.mobileNo : ''}
                                        onChange={(e) => status !== true ? _handleChange(e, 'phone') : _update(e, 'phone')}

                                    />
                                </div>
                                <div className='latElem'>
                                    <div className='newwidthStyle'>
                                        <span className='latElemSpan'>Email Id <span className='asterick'> * </span> :</span>
                                    </div>
                                    <input type='email' className='latlngField' placeholder='Enter Email Id'
                                        name='email'
                                        value={status !== true ? addAddresState.email : dataCopy && dataCopy.email ? dataCopy.email : ''}
                                        onChange={(e) => status !== true ? _handleChange(e, 'email') : _update(e, 'email')}

                                    />
                                </div>
                            </div>
                            <button className='addreddBtn1' style={{ display:'none' }} onClick={() => showAddInp()}>Enter Address</button>
                        </div>
                        <div className='createRouteBtn'>
                            {status !== true ?
                                <button onClick={() =>{ AddAddressBtn(); props.returnFun();}}>Add Address</button>
                                :
                                <button onClick={() => {EditAddress(); props.returnFun();}}>Edit Address</button>
                            }
                        </div>
                    </div>
                </section>
                :
                <section className="custom-modal" style={{ width: '80vw', maxHeight: '90vh' }}>
                    <div className="custom-modal-header">
                        <h1>Locate On Map</h1>
                        <span className="ct-close" onClick={() => _closeMapModal()}></span>
                    </div>

                    <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                        <div className="cust-row">
                            {lat !== '' & lng !== '' ?
                                <div className='mapOuter'>
                                    <GoogleMap
                                        id="mapDimensions"
                                        zoom={18}
                                        center={{ lat: lat, lng: lng }}
                                        onClick={(e) => locateOnMap(e)}
                                    >
                                        {status !== true ?
                                            <Marker onDragEnd={(e) => DragEnd(e)} ref={markerRef} draggable={true} position={{ lat: addAddresState.latitude, lng: addAddresState.longitude }} />
                                            :
                                            <Marker onDragEnd={(e) => DragEnd(e)} ref={markerRef} draggable={true} position={{ lat: dataCopy.latitude, lng: dataCopy.longitude }} />
                                        }
                                    </GoogleMap>

                                    <button className='addLocation' onClick={changeAddress}>Update Address</button>
                                </div>
                                :
                                <p className='alertText'>Unable to fetch location . Please Enter Correct Address.</p>
                            }
                        </div>
                    </div>

                </section>
            }
        </>
    )
}

export default AddAddress;