import { selectInit } from './../../../services/functions'
import { useEffect, useState, useRef } from 'react'
import $ from 'jquery'
import axios from 'axios'
import { getVehiclesList, getAddress, getRequestList, getSlotList } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { getTrips } from './../../../state/redux/actions/index';
import Select from 'react-select';
import history from './../../../services/history';
import Feasible from '../Modal/Feasible'
import moment from 'moment'
import { logoutOnExpiry, url } from './../../../services/requests'
import { colourStyles } from './../../../services/functions'

const CreateTrip = (props) => {
    const dispatch = useDispatch()
    const [addressState, setAddressState] = useState([])
    const [reqState, setReqState] = useState([])
    const listing = useSelector((state) => state.getVehicleRed.vehicleListing)
    const addressListing = useSelector((state) => state.addressListRed.addressListing)
    const reqListing = useSelector((state) => state.getRequestRed.requestListing)
    const slotlisting = useSelector((state) => state.getSlotRed.slotListing)
    const [model, setModel] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [vehState, setVehState] = useState(true)
    const [slotTime, setSlotTime] = useState({
        startTime: '',
        endTime: ''
    })
    const startSlots = [
        { label: '00', value: '00' },
        { label: '01', value: '01' },
        { label: '02', value: '02' },
        { label: '03', value: '03' },
        { label: '04', value: '04' },
        { label: '05', value: '05' },
        { label: '06', value: '06' },
        { label: '07', value: '07' },
        { label: '08', value: '08' },
        { label: '09', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
    ]
    const [ind, setInd] = useState(0)
    const [filteredSlots, setFilteredslots] = useState([])
    const [vehName, setVehName] = useState([])
    const [selectState, setSelectState] = useState('unselect')
    const [vehListing, setVehListing] = useState([])
    const [values, setValues] = useState({
        vehType: '',
        locationName: '',
        requestName: ''
    })
    let user = localStorage.getItem("userType")
    const [dataCluster, setClusterData] = useState()
    const [addCopy, setAddCopy] = useState({
        originAddress: '',
        lat: '',
        lng: ''
    })
    const [endPoint, setEndPoint] = useState({
        address: '',
        lat: '',
        lng: ''
    })
    const [tripState, setTripState] = useState(
        {
            dateOfTrip: "",
            vehicle: [],
            locations: [],
        }
    )
    useEffect(() => {
        dispatch(getSlotList())
    }, [])

    const _setDate = (e) => {
        const currentTime = new Date();
        let hours = currentTime.getHours()
        let minutes = currentTime.getMinutes()
        let seconds = currentTime.getSeconds()
        let result = e.target.value + " " + hours + ":" + minutes + ":" + seconds
        if (user === 'distributor') {
            dispatch(getRequestList({
                date: result
            }))
        }

        setTripState({ ...tripState, [e.target.name]: e.target.value })
    }
    const _handleAddress = (e) => {
        setReqState([])
        let temp = [...addressState]
        const id = e.target.value
        let vehicleNo = tripState.vehicle.length
        let maxLimit = vehicleNo * 11

        if (vehicleNo !== 0) {
            if (addressState.length < maxLimit) {
                temp.push(
                    {
                        _id: addressListing[id]._id,
                        userId: addressListing[id].userId,
                        address: addressListing[id].address,
                        userName: addressListing[id].userName,
                        geofenceId: addressListing[id].geofenceId,
                        status: addressListing[id].status,
                        base: addressListing[id].base,
                        latitude: addressListing[id].latitude,
                        longitude: addressListing[id].longitude,
                        personName: addressListing[id].contactDetails.personName,
                        mobile: addressListing[id].contactDetails.mobileNo,
                        company: addressListing[id].company,
                        startSlotTime: addressListing[id].startSlotTime,
                        endSlotTime: addressListing[id].endSlotTime,
                        quantity: '',
                        shapeType: '',
                        length: '',
                        width: '',
                        height: '',
                        radius: '',
                        volume: ''

                    }
                )
                setAddressState(temp)
            }
            else {
                window.$.fn.show_notification({ message: `Only 11 locations allowed per vehicle..`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
        }
        else {
            window.$.fn.show_notification({ message: `Please select a vehicle first..`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    const _handleAddInput = (e, index, item) => {
        let temp = [...addressState]
        if (item === 'qty') {
            temp[index].quantity = e.target.value
        }
        else if (item === 'length') {
            temp[index].length = Number(e.target.value)
        }
        else if (item === 'width') {
            temp[index].width = Number(e.target.value)
        }
        else if (item === 'height') {
            temp[index].height = Number(e.target.value)
        }
        else if (item === 'radius') {
            temp[index].radius = Number(e.target.value)
        }
        setAddressState(temp)
    }
    const removeVehicle = (item, index) => {
        let dummy = { ...tripState }
        dummy.vehicle.splice(index, 1)
        setTripState(dummy)

        let local = [...vehName]
        local.splice(index, 1)
        setVehName(local)

        let temp = [...vehListing]
        temp.push(item)
        if (temp.length >= 1) {
            temp.splice(0, 1)
            temp.unshift({ label: 'select all', value: 'select all' })
        }
        setVehListing(temp);
    }
    const removeAddress = (index) => {
        let dummy = [...addressState]
        dummy.splice(index, 1)
        setAddressState(dummy)
    }
    const removeRequest = (index) => {
        let dummy = [...reqState]
        dummy.splice(index, 1)
        setReqState(dummy)
    }
    const generateTrip = () => {
        const origin = { latitude: addCopy.lat, longitude: addCopy.lng, address: addCopy.originAddress }
        const finalObj = { ...tripState, origin }
        finalObj.vehicle = tripState.vehicle
        if (addressState.length !== 0) {
            finalObj.locations = addressState
        }
        else if (reqState.length !== 0) {
            finalObj.locations = reqState
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/trip/createTrip`, { ...finalObj }, { headers: config.headers })
            .then(response => {
                const status = response.data.success
                const message = response.data.message
                dispatch(getTrips({
                    page: 1,
                    limit: 10,
                    date: ''
                }))
                if (status === true) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.$.fn.show_notification({ message: `Trip created successfully..`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }


            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
    const _closeModal = () => {
        vehListing.length = 0
        setAddCopy({
            originAddress: '',
            lat: '',
            lng: ''
        })
        setEndPoint({
            address: '',
            lat: '',
            lng: ''
        })
        setTripState(
            {
                dateOfTrip: "",
                vehicle: [],
                locations: [],
            })
        setVehName([])
        setReqState([])
        setAddressState([])
        let temp = [...vehListing]
        listing && listing.map((item, index) => {
            temp.push({
                value: item.vehicleNo,
                label: item.vehicleNo,
                id: item._id,
                indexx: index,
                isDisabled: ''
            });
        });
        setVehListing(temp)
        endPoint.address = ''

        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#CreateTripModal").removeClass("custom-modal-is-visible")

    }

    const VehicleTypes = [
        {
            label: 'Truck Close (14 Feet)',
            value: 'Truck Close (14 Feet)',
            freightRate: 26,
            volume: 17000,
            isDisabled: false
        },
        {
            label: 'Truck Close (17 Feet)',
            value: 'Truck Close (17 Feet)',
            freightRate: 24,
            volume: 22000,
            isDisabled: false
        },
        {
            label: 'Truck Close (20 Feet)',
            value: 'Truck Close (20 Feet)',
            freightRate: 22,
            volume: 28500,
            isDisabled: false

        },
        {
            label: 'Truck Close (22 Feet)',
            value: 'Truck Close (22 Feet)',
            freightRate: 20,
            voume: 35000,
            isDisabled: false
        }
    ]
    const handleVehType = (item) => {
        let dummy = { ...tripState }
        dummy.vehicle.push({
            vehicleType: item.label,
            volume: item.volume,
            freightRate: item.freightRate,
            quantity: 1,
            disabled: true
        })
        setTripState(dummy)
    }
    const _handleQty = (key, index) => {
        if (key === 'sub') {
            let dummy = { ...tripState }
            if (dummy.vehicle[index].quantity > 0) {
                let res = dummy.vehicle[index].quantity - 1
                dummy.vehicle[index].quantity = res
                setTripState(dummy)
            }
        }
        else {
            let dummy = { ...tripState }
            let res = dummy.vehicle[index].quantity + 1
            dummy.vehicle[index].quantity = res
            setTripState(dummy)
        }
    }
    useEffect(() => {
        if (vehState) {
            vehListing.length = 0
            let temp = [...vehListing]
            temp = [vehState ? { label: 'select all', value: 'select all' } : { label: 'De-select all', value: 'De-select all' }]
            setVehListing(temp)
            if (listing && listing.length !== 0) {
                listing && listing.map((item, index) => {
                    temp.push({
                        value: item.vehicleNo,
                        label: item.vehicleNo,
                        id: item._id,
                        indexx: index,
                        isDisabled: ''
                    });
                });
                setVehListing(temp)
            }
        }
        else {
            let temp = [...vehListing]
            temp = [vehState ? { label: 'select all', value: 'select all' } : { label: 'De-select all', value: 'De-select all' }]
            setVehListing(temp)
        }

    }, [listing, vehState])

    const handleVeh = (item) => {

        if (item.value === 'select all') {
            vehListing.length = 0
            vehName.length = 0
            setTripState({
                dateOfTrip: "",
                vehicle: [],
                locations: [],
            })
            setVehState(false)
            setVehListing([])
            setTripState({
                dateOfTrip: "",
                vehicle: [],
                locations: [],
            })
            setVehName([])

            let temp = [...vehListing]
            let dummy = { ...tripState }
            let local = [...vehName]

            listing && listing.map((item, index) => {
                temp.push({
                    value: item.vehicleNo,
                    label: item.vehicleNo,
                    id: item._id,
                    indexx: index,
                    isDisabled: ''
                });
                dummy.vehicle.push(item._id);
                local.push({
                    value: item.vehicleNo,
                    label: item.vehicleNo,
                    id: item._id,
                    indexx: index,
                    isDisabled: ''
                })
            });

            setVehListing(temp)
            setTripState(dummy)
            setVehName(local)

        }
        else if (item.value === 'De-select all') {
            setVehState(true)
            vehListing.length = 0
            let temp = [...vehListing]
            temp = [vehState ? { label: 'select all', value: 'select all' } : { label: 'De-select all', value: 'De-select all' }]
            setVehListing(temp)
            if (listing && listing.length !== 0) {
                listing && listing.map((item, index) => {
                    temp.push({
                        value: item.vehicleNo,
                        label: item.vehicleNo,
                        id: item._id,
                        indexx: index,
                        isDisabled: ''
                    });
                });
                setVehListing(temp)
            }
            setTripState({
                dateOfTrip: "",
                vehicle: [],
                locations: [],
            })
            setVehName([])
        }
        else {
            const newSelect = vehListing.filter(data => data.value !== item.value);
            setVehListing(newSelect);

            let temp = { ...values }
            temp.vehType = item.label
            setValues(temp)

            let dummy = { ...tripState }
            dummy.vehicle.push(item.id)
            setTripState(dummy)

            let local = [...vehName]
            local.push(item)
            setVehName(local)

            if (vehListing.length === 2 && vehListing[0].value == 'select all') {
                let temp = [...vehListing]
                temp = [{ label: 'De-select all', value: 'De-select all' }]
                setVehListing(temp)
            }
        }
    }















    const origin = []
    addressListing && addressListing.map((item) => {
        origin.push({ value: item._id, label: `${item.address} (${item.company})`, address: item.address, lat: item.latitude, lng: item.longitude });
    });
    const handleOrigin = (item) => {
        let temp = { ...addCopy }
        temp.originAddress = item.address
        temp.lat = item.lat
        temp.lng = item.lng
        setAddCopy(temp)
    }
    const handleDestination = (item) => {
        let temp = { ...endPoint }
        temp.address = item.address
        temp.lat = item.lat
        temp.lng = item.lng
        setEndPoint(temp)
    }
    const locations = []
    addressListing && addressListing.map((item) => {
        locations.push({
            value: item._id,
            label: `${item.address} (${item.company})`,
            address: item.address,
            userId: item.userId,
            userName: item.userName,
            geofenceId: item.geofenceId,
            status: item.status,
            base: item.base,
            latitude: item.latitude,
            longitude: item.longitude,
            personName: item.contactDetails.personName,
            mobile: item.contactDetails.mobileNo,
            company: item.company,
            startSlotTime: item.startSlotTime,
            endSlotTime: item.endSlotTime,
            quantity: ''
        });
    });
    const handleLocation = (item) => {
        setReqState([])
        let temp = [...addressState]
        let unique = Date.now() + Math.random()
        let _id = Math.trunc(unique)
        let vehicleNo = tripState.vehicle.length
        let maxLimit = vehicleNo * 11
        if (vehicleNo !== 0) {
            if (addressState.length < maxLimit) {
                setAddressState(temp)
                temp.push(
                    {
                        id: _id,
                        userId: item.userId,
                        address: item.address,
                        userName: item.userName,
                        geofenceId: item.geofenceId,
                        status: item.status,
                        base: item.base,
                        latitude: item.latitude,
                        longitude: item.longitude,
                        personName: item.personName,
                        mobile: item.mobile,
                        company: item.company,
                        startSlotTime: '',
                        endSlotTime: '',
                        quantity: '',
                        shapeType: '',
                        length: '',
                        width: '',
                        height: '',
                        radius: '',
                        volume: ''


                    }
                )
                let local = { ...values }
                local.locationName = item.address
                setValues(local)

            }
            else {
                window.$.fn.show_notification({ message: `Only 11 locations allowed per vehicle..`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
        }
        else {
            window.$.fn.show_notification({ message: `Please select a vehicle first..`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    const requests = [tripState.dateOfTrip !== '' ? { label: selectState === 'unselect' ? 'select all' : 'de-select all', value: 'select all' } : { label: 'Select Date First', value: 'Choose Date', isDisabled: true }]
    reqListing && reqListing.map((item) => {
        if (tripState.dateOfTrip !== '') {
            requests.push({
                value: item._id,
                label: `${item.address.address}(${moment(item.date).format("DD-MM-YYYY")}), ${item?.contactDetail?.personName}(${item?.contactDetail?.mobileNo}})`,
                quantity: item.quantity,
                address: item.address.address,
                status: item.address.status,
                latitude: item.address.latitude,
                longitude: item.address.longitude,
                startSlotTime: item.startSlotTime,
                endSlotTime: item.endSlotTime,
                date: item.date,
                volume: item.volume,
                email: 'contactDetail' in item ? item.contactDetail.email : '-',
                mobile: 'contactDetail' in item ? item.contactDetail.mobileNo : '-',
                personName: 'contactDetail' in item ? item.contactDetail.personName : '-',
                distributorId: item.distributorId,
                consumerId: item.consumerId
            });
        }
        else {
        }
    });
    const handleRequests = (item, requests) => {
        setAddressState([])
        let temp = [...reqState]

        if (item.label === 'select all') {
            if (selectState === 'unselect') {
                setReqState([])
                let temp = []
                setSelectState('all')
                requests.map((item, index) => { return (index !== 0 ? temp.push(item) : null) })
                setReqState(temp)
                let local = { ...values }
                local.requestName = 'All Selected'
                setValues(local)
            }
        }
        else if (item.label === 'de-select all') {
            setSelectState('unselect')
            setReqState([])
        }
        else if (item.label === 'Choose Date') {
            // myRef.current.click()
        }
        else {
            temp.push(
                {
                    _id: item.value,
                    userId: item.userId,
                    quantity: item.quantity,
                    address: item.address,
                    userName: item.userName,
                    geofenceId: item.geofenceId,
                    status: item.status,
                    base: item.base,
                    date: item.date,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    startSlotTime: item.startSlotTime,
                    endSlotTime: item.endSlotTime,
                    length: item.length,
                    width: item.width,
                    height: item.height,
                    radius: item.radius,
                    volume: item.volume,
                    shapeType: item.shapeType,
                    email: item.email,
                    mobile: item.mobile,
                    personName: item.personName
                }

            )
            setReqState(temp)
            let local = { ...values }
            local.requestName = item.address
            setValues(local)
        }


    }
    const previewCluster = () => {
        const origin = { latitude: addCopy.lat, longitude: addCopy.lng, address: addCopy.originAddress }
        const destination = { latitude: endPoint.lat, longitude: endPoint.lng, address: endPoint.address }
        const finalObj = { ...tripState, origin, destination }
        finalObj.vehicle = tripState.vehicle
        if (addressState.length !== 0) {
            finalObj.locations = addressState
            // finalObj.locations = {...addressState[0],...addressState,...shapeToggle }
        }
        else if (reqState.length !== 0) {
            finalObj.locations = reqState
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let result = addressState.every((a) => {
            return a.quantity !== ''
        })

        if (result === true) {
            setOverlay(true)
            axios.post(`${url}/routeOptimizer/getRoute`, { ...finalObj }, { headers: config.headers })
                .then(response => {
                    setOverlay(false)
                    const status = response.data.success
                    const message = response.data.message
                    const infeasibleArray = response.data
                    let clusterArr = response.data
                    if (status === true) {
                        if ("infeasible" in infeasibleArray) {
                            setClusterData(clusterArr)
                            $(".custom-modal-overlay").addClass("custom-modal-is-visible")
                            $("#FeasibleModal").addClass("custom-modal-is-visible")

                            // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                            $("#CreateTripModal").removeClass("custom-modal-is-visible")
                        }
                        else {
                            $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                            $(".custom-modal").removeClass("custom-modal-is-visible")
                            history.push({
                                pathname: '/dashboard/trip/preview',
                                state: { clusterArr: clusterArr, previewPayload: finalObj }
                            })
                        }
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    setOverlay(false)
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
        else {
            window.$.fn.show_notification({ message: `Quantity cannot be empty...`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    const timeSlots = [
        { label: '00', value: '00' },
        { label: '01', value: '01' },
        { label: '02', value: '02' },
        { label: '03', value: '03' },
        { label: '04', value: '04' },
        { label: '05', value: '05' },
        { label: '06', value: '06' },
        { label: '07', value: '07' },
        { label: '08', value: '08' },
        { label: '09', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
    ]
    const handleStartChange = (item, index) => {
        let local = [...addressState]
        local[index].startSlotTime = item.label
        setAddressState(local)
    }
    const handleEndChange = (item, index) => {
        let local = [...addressState]
        local[index].endSlotTime = item.label
        setAddressState(local)
    }
    const slotLists = []
    slotlisting && slotlisting.map((item) => {
        slotLists.push({
            value: item._id,
            label: `${item.startTime} - ${item.endTime}`,
            start: item.startTime,
            end: item.endTime
        });
    });
    const handleSlotChange = (item, index) => {
        let local = [...addressState]
        local[index].startSlotTime = item.start
        local[index].endSlotTime = item.end
        setAddressState(local)
    }
    const toggleModal = (index) => {
        setInd(index)
        setModel(!model)
    }
    const handleModelChange = (item, key) => {

        if (key === 'start') {
            filteredSlots.length = 0
            let local = { ...slotTime }
            local.startTime = Number(item.label)
            setSlotTime(local)

            let num = Number(item.label)
            for (let i = num + 1; i <= 24; i++) {
                filteredSlots.push({
                    label: i <= 9 ? `0` + i : i, value: i
                })
            }
        }
        else if (key === 'end') {
            let local = { ...slotTime }
            local.endTime = Number(item.label)
            setSlotTime(local)
        }
    }
    const addSlot = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/setting/addTimeSettings`, { ...slotTime }, { headers: config.headers })
            .then(response => {
                const status = response.data.success
                const message = response.data.message
                dispatch(getSlotList())
                if (status === true) {
                    setSlotTime({
                        startTime: '',
                        endTime: ''
                    })
                    dispatch(getSlotList())
                    setModel(!model)
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
    const shapes = [
        { value: 'select', label: 'Select' },
        { value: 'Box', label: 'Box' },
        { value: 'Cylinder', label: 'Cylinder' }
    ];
    const _handleShapeType = (item, index) => {
        let local = [...addressState]
        if (item.label === 'Box') {
            local[index].shapeType = 'Box'
            setAddressState(local)
        }
        else if (item.label === 'Cylinder') {
            local[index].shapeType = 'Cylinder'
            setAddressState(local)
        }
    }
    const [inputValue, setInputValue] = useState('');
    const calculation = (index) => {
        let temp = [...addressState]
        if (temp[index].shapeType === 'Box') {
            let res = Math.ceil(temp[index].length * temp[index].width * temp[index].height)
            temp[index].volume = res
            setAddressState(temp)
        }
        else if (temp[index].shapeType === 'Cylinder') {
            let res = Math.ceil(3.145 * temp[index].radius * temp[index].radius * temp[index].height)
            temp[index].volume = res
            setAddressState(temp)
        }

    }

    const handleInputChange = (inputValue) => {
        setInputValue(inputValue);
      };
    
      const filterOptions = (options) => {
        if (inputValue === '') {
            return options.slice(0, 500);
          }
          const filteredOptions = options.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          );
          return filteredOptions;
      };
    return (
        <>

            <section id="CreateTripModal" className="custom-modal toggle_width" style={{ width: "82%", }}>
                <div className="custom-modal-header">
                    <h1>Create Trip</h1>
                    <span className={overlay === false ? "ct-close" : "hide-ct-close"} onClick={_closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{ maxHeight: '78vh', overflowY: 'auto' }}>
                    <div className='trip100' style={{ background: 'none', borderLeft: '0px', boxShadow: 'none', border: 'none' }}>
                        <div className='trip1000'>
                            <span className='tripSpan'>Date <span className='asterick'> * </span> :</span>
                            <input min={new Date().toISOString().split('T')[0]} name='dateOfTrip' value={tripState.dateOfTrip} onChange={(e) => _setDate(e)} type='date' className='tripInputNew' />
                        </div>
                    </div>
                    <div className='combineElem'>
                        <div className='trip100'>
                            <div className='trip48'>
                                <div className='trip40'>
                                    <span className='tripSpan'>Select Vehicle <span className='asterick'> * </span>  :</span>
                                </div>
                                <div className='trip60 reactSelect'>
                                {vehName.length ?
                                    <Select
                                        onChange={(value) => handleVeh(value)}
                                        options={vehListing}
                                        styles={colourStyles}
                                        hideSelectedOptions={true}
                                        value={
                                            vehState ?
                                                { label: vehName && vehName.length === 0 ? 'Select' : values.vehType, value: vehName && vehName.length === 0 ? 'select' : values.vehType }
                                                :
                                                { label: ' All selected', value: 'All selected' }
                                        }
                                    />
                                    :
                                    <Select
                                        onChange={(value) => handleVeh(value)}
                                        options={vehListing}
                                        styles={colourStyles}
                                        hideSelectedOptions={true}
                                        value={{label:'Select' , value:'Select'}}
                                    />
                                }
                                </div>
                            </div>
                            <div className='trip48New'>
                                {vehName && vehName.length !== 0 ?
                                    vehName && vehName.map((item, index) => {
                                        return (
                                            <div key={index} className='newGroup'>
                                                <i className="fa fa-times newgroupCross" onClick={() => removeVehicle(item, index)} ></i>
                                                <span>{item.label}</span>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className='notFound'>
                                        <span>Choose Vehicle from dropdown</span>
                                    </div>
                                }


                            </div>
                        </div>

                    </div>
                    <div className='combineElem'>
                        <div className='trip100' style={{ marginBottom: '0px', borderBottom: 'none' }}>
                            <div className='trip48'>
                                <div className='trip40'>
                                    <span className='tripSpan'>Select Origin <span className='asterick'> * </span> :</span>
                                </div>
                                <div className='trip60 reactSelect'>
                                    <Select
                                        onChange={(value) => handleOrigin(value)}
                                        options={origin}
                                        styles={colourStyles}
                                        value={{ label: addCopy.originAddress === '' ? 'Select' : addCopy.originAddress, value: addCopy.originAddress === '' ? 'Select' : addCopy.originAddress }}
                                    />
                                </div>
                            </div>

                            <div className='trip48New'>
                                {addCopy.originAddress !== '' ?
                                    <div className='groupListView newOrgin' style={{ border: 'none' }}>
                                        {/* <i className="fa fa-times groupCross" onClick={() => removeAddress(index)} ></i> */}
                                        <div className='grouplist2 newgroup'>
                                            <span className='groupSpan' style={{ fontSize: '1em' }}>{addCopy.originAddress}</span>
                                        </div>
                                    </div>
                                    :
                                    <div className='notFound'>
                                        <span>Choose Origin from dropdown</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='trip100' style={{ borderTop: 'none' }}>
                            <div className='trip48'>
                                <div className='trip40'>
                                    <span className='tripSpan'>Select Destination <span className='asterick'> * </span> :</span>
                                </div>
                                <div className='trip60 reactSelect'>
                                    <Select
                                        onChange={(value) => handleDestination(value)}
                                        options={origin}
                                        styles={colourStyles}
                                        value={{ label: endPoint.address === '' ? 'Select' : endPoint.address, value: endPoint.address === '' ? 'Select' : endPoint.address }}
                                    />
                                </div>
                            </div>

                            <div className='trip48New'>
                                {endPoint.address !== "" ?
                                    <div className='groupListView newOrgin' style={{ border: 'none' }}>
                                        {/* <i className="fa fa-times groupCross" onClick={() => removeAddress(index)} ></i> */}
                                        <div className='grouplist2 newgroup'>
                                            <span className='groupSpan' style={{ fontSize: '1em' }}>{endPoint.address}</span>
                                        </div>
                                    </div>
                                    :
                                    <div className='notFound'>
                                        <span>Choose Destination from dropdown</span>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                    <div className='combineElem'>
                        <div className='trip100' style={{ marginBottom: '0px', borderBottom: 'none' }}>
                            <div className='trip48' style={{ position: 'relative' }}>
                                <div className='trip40'>
                                    <span className='tripSpan'>Select Requests <span className='asterick'> * </span> :</span>
                                </div>
                                {/* <div className='allDivabs'>
                                <span className='selectAll'>Select all</span>
                                <input className='customCheckbox1' type='checkbox' />
                            </div> */}

                                <div className='trip60 reactSelect' >
                                    <Select
                                        onChange={(value) => handleRequests(value, requests)}
                                        options={filterOptions(requests)}
                                        styles={colourStyles}
                                        onInputChange={handleInputChange}
                                        value={{ label: reqState && reqState.length === 0 ? 'Select' : values.requestName, value: reqState && reqState.length === 0 ? 'select' : reqState.requestName }}
                                    />
                                    {reqState && reqState.length !== 0 ?
                                        <span className='totalAbs'>Total Requests - {reqState ? reqState.length : ''}</span>
                                        : ''}
                                </div>


                            </div>
                            <div className='trip48New'>
                                {reqState && reqState.length !== 0 ?
                                    reqState && reqState.map((item, index) => {
                                        console.log('ite',item)
                                        return (
                                            <div key={index} className='groupListView asignFlex' style={{ paddingTop: '7px', paddingBottom: '7px', flexDirection: 'column' }}>
                                                <span className='reqAbs'>{index + 1}</span>
                                                <i className="fa fa-times groupCross" onClick={() => removeRequest(index)} ></i>
                                                <div className='groupListViewIn groupListViewInHgt'>
                                                    <div className='grouplist2' style={{ width: '50%' }}>
                                                        <span className='tripSpan' style={{ fontSize: '1em' }}>Customer Name- </span>
                                                        <span className='groupSpan'>{item?.personName || "-"}</span>
                                                    </div>
                                                    <div className='grouplist2' style={{ width: '50%' }}>
                                                        <span className='tripSpan' style={{ fontSize: '1em' }}>Mobile Number- </span>
                                                        <span className='groupSpan'>{item?.mobile || "-"}</span>
                                                    </div>
                                                </div>
                                                <div className='groupListViewIn groupListViewInHgt'>
                                                    <div className='grouplist2' style={{ width: '100%' }}>
                                                        <span className='tripSpan' style={{ fontSize: '1em' }}>Address- </span>
                                                        <span className='groupSpan'>{item.address}</span>
                                                    </div>
                                                </div>
                                               
                                                <div className='groupListViewIn groupListViewInHgt'>
                                                    <div className='grouplist2'>
                                                        <span className='tripSpan' style={{ fontSize: '1em' }}>Time Slot - </span>
                                                        <span className='groupSpan'>{item.startSlotTime ? `${item.startSlotTime} - ${item.endSlotTime}` : 'NaN'}</span>
                                                    </div>
                                                    <div className='grouplist3'>
                                                        <span className='tripSpan' style={{ fontSize: '1em' }}>Qty :</span>
                                                        <input className='tripInput' disabled={true} value={item.quantity} style={{ width: '60%', background: '#F5F5F5' }} placeholder='Quantity' />
                                                    </div>
                                                </div>
                                                {/* <div className='groupListViewIn groupListViewInHgt'> */}
                                                {/* {item.shapeType === 'Box' ?
                                                        <div className='grouplist2 toggleWidth'>
                                                            <span className='tripSpan' style={{ fontSize: '1em' }}>Length - </span>
                                                            <span className='groupSpan'>{item.length} cm</span>
                                                        </div>
                                                        : ''}
                                                    {item.shapeType === 'Box' ?
                                                        <div className='grouplist2 toggleWidth'>
                                                            <span className='tripSpan' style={{ fontSize: '1em' }}>Width - </span>
                                                            <span className='groupSpan'>{item.width} cm</span>
                                                        </div>
                                                        : ''}
                                                    {item.shapeType === 'Cylinder' ?
                                                        <div className='grouplist2 toggleWidth'>
                                                            <span className='tripSpan' style={{ fontSize: '1em' }}>Pi - </span>
                                                            <span className='groupSpan'>3.145</span>
                                                        </div>
                                                        : ''}
                                                    {item.shapeType === 'Cylinder' ?
                                                        <div className='grouplist2 toggleWidth'>
                                                            <span className='tripSpan' style={{ fontSize: '1em' }}>Radius - </span>
                                                            <span className='groupSpan'>{item.radius} cm</span>
                                                        </div>
                                                        : ''}
                                                    <div className='grouplist2 toggleWidth'>
                                                        <span className='tripSpan' style={{ fontSize: '1em' }}>Height - </span>
                                                        <span className='groupSpan'>{item.height} cm</span>
                                                    </div> */}
                                                {/* <div className='grouplist2'>
                                                        <span className='tripSpan' style={{ fontSize: '1em', marginRight: '18px' }}>Vol :</span>
                                                        <span className='tripInput toggleWidthNew' style={{ width: '66%', background: '#F5F5F5' }}>{item.volume} cm<sup>3</sup> </span>
                                                    </div> */}
                                                {/* </div> */}
                                            </div>

                                        )
                                    })
                                    :
                                    <div className='notFound'>
                                        <span>Choose Requests from dropdown</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='trip100' style={{ borderTop: 'none' }}>
                            {/* {reqState && reqState.length === 0 && addressState && addressState.length === 0 ?
                                <span className='orSpan'>OR</span>
                                : ''
                            } */}
                            {/* <div className='trip48'>
                                <div className='trip40'>
                                    <span className='tripSpan'>Select Locations :</span>
                                </div>
                                <div className='trip60 reactSelect'>
                                    <Select
                                        onChange={(value) => handleLocation(value)}
                                        options={locations}
                                        styles={colourStyles}
                                        value={{ label: addressState && addressState.length === 0 ? 'Select' : values.locationName, value: addressState && addressState.length === 0 ? 'select' : values.locationName }}
                                    />
                                    {addressState && addressState.length !== 0 ?
                                        <span className='totalAbs'>Total Locations - {addressState ? addressState.length : ''}</span>
                                        : ''}
                                </div>
                            </div> */}

                            {/* <div className='trip48New'> */}
                                {addressState && addressState.length !== 0 ? ""
                                    // addressState && addressState.map((item, index) => {
                                    //     return (
                                    //         <div key={index} className='groupListView asignFlex'>
                                    //             <span className='reqAbs'>{index + 1}</span>
                                    //             <i className="fa fa-times groupCross" onClick={() => removeAddress(index)} ></i>
                                    //             <div className='groupListViewIn groupListViewInHgt'>
                                    //                 <div className='grouplist2' style={{ width: '70%' }}>
                                    //                     <span className='tripSpan' style={{ fontSize: '1em' }}>Company & Person Name- </span>
                                    //                     <span className='groupSpan'>{item.company} , {item.personName}</span>
                                    //                 </div>
                                    //                 <div className='grouplist3' style={{ width: '30%' }}>
                                    //                     <span className='tripSpan' style={{ fontSize: '1em' }}>Mobile No.- </span>
                                    //                     <span className='groupSpan'>{item.mobile}</span>
                                    //                 </div>
                                    //             </div>
                                    //             <div className='groupListViewIn groupListViewInHgt'>
                                    //                 <div className='grouplist2' style={{ width: '100%' }}>
                                    //                     <span className='tripSpan' style={{ fontSize: '1em' }}>Address- </span>
                                    //                     <span className='groupSpan'>{item.address}</span>
                                    //                 </div>
                                    //             </div>
                                    //             <div className='groupListViewIn groupListViewInHgt'>
                                    //                 <div className='slotElemGrand' style={{ width: '100%' }}>
                                    //                     <span className=' newtripSpan' style={{ fontSize: '1em' }}>Time Slot - </span>

                                    //                     <div className='slotElemParent' style={{ width: '100%', background: 'transparent' }}>
                                    //                         <div className='slotElementn' style={{ position: 'relative' }}>
                                    //                             {slotlisting && slotlisting.length === 0 ?
                                    //                                 <>
                                    //                                     <Select
                                    //                                         onChange={(value) => handleStartChange(value, index)}
                                    //                                         options={timeSlots}
                                    //                                         styles={colourStyles}
                                    //                                     />
                                    //                                     <Select
                                    //                                         onChange={(value) => handleEndChange(value, index)}
                                    //                                         options={timeSlots}
                                    //                                         styles={colourStyles}
                                    //                                     />
                                    //                                 </>
                                    //                                 :
                                    //                                 <Select
                                    //                                     onChange={(value) => handleSlotChange(value, index)}
                                    //                                     options={slotLists}
                                    //                                     styles={colourStyles}
                                    //                                 />
                                    //                             }
                                    //                             {slotlisting && slotlisting.length !== 0 ?
                                    //                                 <button className='addSlotsBtn' onClick={() => toggleModal(index)}>Add Slots</button>
                                    //                                 : ""}
                                    //                             {index === ind ?
                                    //                                 model && (
                                    //                                     <div className='addCtegoryModal'>
                                    //                                         <div className='closeContModal' onClick={() => setModel(!model)}>
                                    //                                             <i id="headingCross" className="fa fa-times"></i>
                                    //                                         </div>
                                    //                                         <div className='addCategInner'>
                                    //                                             <span className='categoryModalSpan'>Choose Your Slot :</span>
                                    //                                             <div className='modiFyModel'>
                                    //                                                 <Select
                                    //                                                     value={{ label: slotTime.startTime === '' ? 'Select' : slotTime.startTime, value: slotTime.startTime === '' ? 'Select' : slotTime.startTime }}
                                    //                                                     onChange={(value) => handleModelChange(value, 'start')}
                                    //                                                     options={startSlots}
                                    //                                                     styles={colourStyles}
                                    //                                                 />
                                    //                                                 <Select
                                    //                                                     value={{ label: slotTime.endTime === '' ? 'Select' : slotTime.endTime, value: slotTime.endTime === '' ? 'Select' : slotTime.startTime }}
                                    //                                                     onChange={(value) => handleModelChange(value, 'end')}
                                    //                                                     options={filteredSlots}
                                    //                                                     styles={colourStyles}
                                    //                                                 />
                                    //                                             </div>
                                    //                                             <button className='categoryModelBtn' onClick={addSlot}>Add Slot</button>
                                    //                                         </div>


                                    //                                     </div>
                                    //                                 )
                                    //                                 : ''}
                                    //                         </div>
                                    //                         <div className='shapeTypeDiv grouplist3'>
                                    //                             <span className='tripSpan' style={{ fontSize: '1em' }}>Qty- </span>
                                    //                             <input name='quantity' onChange={(e) => _handleAddInput(e, index, 'qty')} className='tripInput' style={{ width: '60%', background: '#F5F5F5' }} type='number' placeholder='Quantity' />
                                    //                         </div>
                                    //                     </div>

                                    //                 </div>
                                    //             </div>
                                    //             {/* <div className='groupListViewIn '>
                                    //             <div className='shapeTypeDiv'>
                                    //                 <span className=' newtripSpan' style={{ fontSize: '1em' }}>Type - </span>
                                    //                 <Select
                                    //                     onChange={(value) => _handleShapeType(value, index)}
                                    //                     options={shapes}
                                    //                     styles={colourStyles}
                                    //                 />
                                    //             </div>
                                    //             <div className='shapeTypeDiv grouplist3'>
                                    //                 <span className='tripSpan' style={{ fontSize: '1em' }}>Qty- </span>
                                    //                 <input name='quantity' onChange={(e) => _handleAddInput(e, index, 'qty')} className='tripInput' style={{ width: '60%' }} type='number' placeholder='Quantity' />
                                    //             </div>
                                    //         </div>
                                    //         {addressState[index].shapeType !== '' ?
                                    //             <div className='groupListViewIn'>
                                    //                 {addressState[index].shapeType === 'Box' ?
                                    //                     <div className='grouplist2 toggleWidth'>
                                    //                         <span className='tripSpan' style={{ fontSize: '1em' }}>Length - </span>
                                    //                         <input type='number' name='length'
                                    //                             onChange={(e) => _handleAddInput(e, index, 'length')}
                                    //                             onKeyUp={() => calculation(index)}
                                    //                             placeholder='Length' className='custInputProd' />
                                    //                     </div>
                                    //                     : ""}

                                    //                 {addressState[index].shapeType === 'Box' ?
                                    //                     <div className='grouplist2 toggleWidth'>
                                    //                         <span className='tripSpan' style={{ fontSize: '1em' }}>Width - </span>
                                    //                         <input type='number' name='width'
                                    //                             onChange={(e) => _handleAddInput(e, index, 'width')}
                                    //                             onKeyUp={() => calculation(index)}
                                    //                             placeholder='Width' className='custInputProd' />
                                    //                     </div>
                                    //                     : ""}
                                    //                 {addressState[index].shapeType === 'Cylinder' ?
                                    //                     <div className='grouplist2 toggleWidth'>
                                    //                         <span className='tripSpan' style={{ fontSize: '1em' }}>Pi - </span>
                                    //                         <input type='number' name='radius'
                                    //                             value="3.145"
                                    //                             className='custInputProd' />
                                    //                     </div>
                                    //                     : ''}
                                    //                 {addressState[index].shapeType === 'Cylinder' ?
                                    //                     <div className='grouplist2 toggleWidth'>
                                    //                         <span className='tripSpan' style={{ fontSize: '1em' }}>Radius - </span>
                                    //                         <input type='number' name='radius'
                                    //                             onChange={(e) => _handleAddInput(e, index, 'radius')}
                                    //                             onKeyUp={() => calculation(index)}
                                    //                             placeholder='Radius' className='custInputProd' />
                                    //                     </div>
                                    //                     : ''}
                                    //                 <div className='grouplist2 toggleWidth'>
                                    //                     <span className='tripSpan' style={{ fontSize: '1em' }}>Height - </span>
                                    //                     <input type='number' name='height'
                                    //                         onChange={(e) => _handleAddInput(e, index, 'height')}
                                    //                         onKeyUp={() => calculation(index)}
                                    //                         placeholder='Height' className='custInputProd' />
                                    //                 </div>
                                    //                 <div className='grouplist2 toggleWidthh'>
                                    //                     <span className='tripSpan' style={{ fontSize: '1em', marginRight: '18px' }}>Vol-</span>
                                    //                     <span className='tripInput toggleWidthNew' style={{ width: '66%', background: '#fff' }}>{addressState[index].volume} cm<sup>3</sup> </span>
                                    //                 </div>
                                    //             </div> */}
                                    //             {/* : ""} */}

                                    //         </div>
                                    //     )
                                    // })
                                    :
                                    ""
                                    // <div className='notFound'>
                                    //     <span>Choose Address from dropdown</span>
                                    // </div>
                                }
                            {/* </div> */}
                        </div>

                    </div>
                    <div className='customBtnElem'>
                        <button className='customBtn'
                            disabled={tripState.dateOfTrip === '' || vehName.length === 0 || addCopy.originAddress === '' || endPoint.address === '' || reqState.length === 0 ? true : false}
                            onClick={() => { previewCluster(); props.returnFun(); }}>Preview</button>
                    </div>

                </div>
                {overlay === false ? '' :
                    <div className='overlayModal'>
                        <div className='overlayContent'>
                            <span className='routePreview'>Evaluating Your Constraints.....</span>
                            <i className="fa fa-spinner fa-spin loadingSpinner"></i>
                        </div>
                    </div>
                }
            </section>
            <Feasible dataCluster={dataCluster} />
        </>
    )
}

export default CreateTrip;

{/* <div key={index} className='group' style={{ position: 'relative' }}>
                                            <i className="fa fa-times groupCross" onClick={() => removeVehicle(index)} ></i>
                                            <div className='typeContainerOuter'>
                                                <div className='typeContainer'>
                                                    <span className='tHead'>Vehicle Type :</span>
                                                    <span className='typeName'>{item.vehicleType}</span>
                                                </div>
                                                <div className='qtyContainer'>
                                                    <div className='qtyOper'>
                                                        <i className="fa fa-minus-circle qtySub" onClick={() => _handleQty('sub', index)}></i>
                                                    </div>
                                                    <div className='qtyOper addBdr'>
                                                        <span className='qtyNum'>{item.quantity}</span>
                                                    </div>
                                                    <div className='qtyOper'>
                                                        <i className="fa fa-plus-circle qtyAdd" onClick={() => _handleQty('add', index)}></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='typeContainerOuter'>
                                                <div className='tCont50'>
                                                    <span className='tHead'>Vehicle Volume :</span>
                                                    <span className='tSpan'>{item.volume} cm<sup>3</sup></span>
                                                </div>
                                                <div className='tCont50' style={{ justifyContent: 'flex-end', marginRight: '14px' }}>
                                                    <span className='tHead'>Freight Rate :</span>
                                                    <span className='tSpan'>{item.freightRate} / KM</span>
                                                </div>
                                            </div>
                                        </div> */}