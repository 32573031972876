import React, { useEffect, useState } from 'react';
import { getAreasList, getCustomerLists } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { selectInit } from './../../services/functions'
import Select from 'react-select';
import LocationPlot from './Modal/LocationPlot'
import $ from 'jquery'
import { colourStyles } from '../../services/functions'
import { Table } from 'react-bootstrap'
const CustomerManagement = () => {
    const dispatch = useDispatch()
    const [pageLimit, setPageLimit] = useState(10)
    const areaLists = useSelector((state) => state.getAreasRed.areaLists)
    const listing = useSelector((state) => state.getCustRed.customerList)
    const loader = useSelector((state) => state.getCustRed.loading)
    const message = useSelector((state) => state.getCustRed.message)
    const count = useSelector((state) => state.getCustRed.count)
    const [geo, setGeo] = useState({ lat: '', lng: '' })
    const [searchState, setSearchState] = useState({
        areaId: '',
        searchValue: '',
        searchType: '',
        location: '',
        locationLabel:'',
        limit: 10
    })
    const [pageCount, setPageCount] = useState(1)
    const _searchDrop = (id, value) => {
        // let temp = { ...searchObj }
        // temp.searchType = id
        // setSearchObject(temp)

    }

    const areaListing = []
    areaLists && areaLists.map((item) => {
        areaListing.push({ value: item.areaId, label: item.areaDescription });
    });

    const searchTypes = [
        // {label :'Select', value:'Select'},
        { label: 'Name', value: 'name' },
        { label: 'Mobile', value: 'mobile' },
        { label: 'Address', value: 'address' }
    ]

    const consumerTypes = [
        { label: 'All', value: 'all' },
        { label: 'Available', value: 'true' },
        { label: 'Not-Available', value: 'false' },
    ]

    const handleAreas = (item) => {
        setPageCount(1)
        let temp = { ...searchState }
        temp.areaId = item.value
        temp.location = 'all'
        temp.locationLabel = 'All'
        setSearchState(temp)

        dispatch(getCustomerLists({
            areaId: item.value,
            searchValue: '',
            searchType: '',
            location:'all',
            page: 1,
            limit: pageLimit,
        }))
    }

    const handleConsumerStatus = (item) => {
        let temp = { ...searchState }
        temp.location = item.value
        temp.locationLabel = item.label
        setSearchState(temp)
        setPageCount(1)

        dispatch(getCustomerLists({
            areaId: searchState?.areaId,
            location: item.value,
            searchValue: '',
            searchType: '',
            page: 1,
            limit: pageLimit,
        }))
    }
    const handleSearch = (item) => {
        let temp = { ...searchState }
        temp.searchType = item.value
        setSearchState(temp)
    }
    const _change = (e) => {
        let temp = { ...searchState }
        temp.searchValue = e.target.value
        setSearchState(temp)
    }
    const triggerSearch = () => {
        dispatch(getCustomerLists({
            areaId: searchState.areaId,
            searchValue: searchState.searchValue,
            searchType: searchState.searchType,
            location:searchState.location,
            page: 1,
            limit: pageLimit,
        }))
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 50)
        dispatch(getAreasList())
    }, [])
    const nextPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount += 1);
        });
    }
    const prevPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount -= 1);
        });
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const shareData = (item, index) => {
        let temp = { ...geo }
        temp.lat = item && item.latestLocation ? item.latestLocation.latitude : '-'
        temp.lng = item && item.latestLocation ? item.latestLocation.longitude : '-'
        setGeo(temp)
    }
    useEffect(() => {
        dispatch(getCustomerLists({
            areaId: searchState.areaId,
            searchValue: '',
            searchType: '',
            location: searchState.location,
            page: pageCount,
            limit: pageLimit,
        }))
    }, [dispatch, pageCount])

    const entries = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 }
    ]

    const handleChange = (item) => {
        let count = item.value
        setPageCount(1)
        setPageLimit(count)
        dispatch(getCustomerLists({
            areaId: searchState.areaId,
            page: 1,
            location: searchState.location,
            limit: item.value,
            searchType: '',
            searchValue: ''
        }))
    }

    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        Consumer Management
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div className='bdaOuter'>
                            <div className='searcBox alignCen' style={{ width: '100%', flexWrap: 'wrap', display: 'flex' }}>
                                <div className='filterDiv' style={{ marginRight: '12px' }}>
                                    <Select
                                        onChange={(value) => handleAreas(value)}
                                        options={areaListing}
                                        styles={colourStyles}
                                    />
                                </div>

                                <div className="filterDiv filterDivFlex" >
                                    <div className="filterDivFlexDrop" style={{ zIndex: '10', marginRight: '4px' }}>
                                        <Select
                                            onChange={(value) => handleSearch(value)}
                                            options={searchTypes}
                                            styles={colourStyles}
                                        />
                                    </div>
                                    <input type="text" name='searchValue' onChange={(e) => _change(e)} className="form-control" placeholder="Search here..." style={{ zIndex: '10', height: '36px', width: '70%' }} />
                                    <div className="input-group-append" >
                                        <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2" style={{ height: '36px' }} onClick={() => triggerSearch()}>
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>

                                <div className='filterDiv' style={{ marginLeft: '12px' }}>
                                    <Select
                                        onChange={(value) => handleConsumerStatus(value)}
                                        options={consumerTypes}
                                        styles={colourStyles}
                                        value={{label: searchState.locationLabel ? searchState.locationLabel : 'Select..', value: searchState.locationLabel ? searchState.locationLabel : 'Select..'}}
                                    />
                                </div>
                            </div>




                        </div>

                        {loader === false ?
                            listing && listing.length !== 0 ?
                                <div className="generic-table-wrapper mt20">
                                    <Table className="generic-table">
                                        <thead className="bg-accent">
                                            <tr>
                                                <th>S. No</th>
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Area Description</th>
                                                <th>Mobile Number</th>
                                                <th>Latest Location</th>
                                                <th>Area Code & Area ID </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            {listing && listing.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ color: '#007BC9' }}>
                                                            {((pageCount - 1) * (pageLimit)) + index + 1}
                                                        </td>
                                                        <td>{item && item.name ? item.name : '-'}</td>
                                                        <td>{item && item.address ? item.address : '-'}</td>
                                                        <td >{item && item.areaDescription ? item.areaDescription : '-'}</td>
                                                        <td>{item && item.mobile ? item.mobile : '-'}</td>
                                                        <td style={{ textDecoration: 'underline', textUnderlineOffset: '4px', color: '#0079c9', cursor: 'pointer' }} onClick={'latestLocation' in item ? () => { _handleClick('LocationPlot'); shareData(item, index) } : ''}>
                                                            {'latestLocation' in item ? item && item.latestLocation ? item.latestLocation.latitude.toFixed(4) : '-' : ''}  {'latestLocation' in item ? item && item.latestLocation ? ',' + item.latestLocation.longitude.toFixed(4) : '-' :
                                                                <span style={{ color: '#fff' }}>-</span>
                                                            }
                                                        </td>

                                                        <td>{item && item.areaCode ? item.areaCode : '-'} , {item && item.areaId ? item.areaId : '-'}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                :
                                <div className='noData'>
                                    <p>{message}</p>
                                </div>
                            :
                            <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                            </div>

                        }
                        {loader === false && listing && listing.length !== 0 ?
                            <div className='paginationDiv'>
                                <div className='entries'>
                                    <span className='entrieSpan'>Show Entries :</span>
                                    <Select
                                        onChange={(value) => handleChange(value)}
                                        options={entries}
                                        styles={colourStyles}
                                        menuPlacement="auto"
                                    />
                                </div>
                                <button disabled={pageCount === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
                                <button disabled={Math.floor((count + pageLimit - 1) / pageLimit) === pageCount ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
            <LocationPlot geo={geo} />
        </div>
    )
}
export default CustomerManagement;